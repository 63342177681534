/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect } from "react";
import "./MusicPlayer.css";
import { Image, NavDropdown } from "react-bootstrap";
import { Slider } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { useRecoilValue, useSetRecoilState } from "recoil";
import paas from "../../../../assets/pause.png";
import ple from "../../../../assets/play.png";
import projectListdefault from "../../../../assets/projectListdefault.png";
import filledfavourite from "../../../../assets/filledfavourite.png";
import favourite from "../../../../assets/favourite.png";
import det from "../../../../assets/det.png";
import soundf from "../../../../assets/soundf.png";
import dots from "../../../../assets/dots.png";
import addtoprjdpd from "../../../../assets/addtoprjdpd.png";
import { collapsed } from "../../../../states/collapseNav";
import navdrpdwnicn from "../../../../assets/navdrpdwnicn.png";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { projectState } from "../../../../states/projects";
import AddNewProject from "../ProjectComponents/AddNewProject";
import { bootstrapState } from "../../../../states/bootstrap";
import {
  postProjects,
  getProjects,
  postExistingProjectFavorite,
  removeFavorite,
  addFavorite,
} from "../../../../services/soniqServices";
import { userState } from "../../../../states/user";

function MusicPlayer({
  data,
  keyName,
  setPInd,
  playingIndex,
  setMPD,
  type,
  getF,
  soundLibrary,
  openedId,
  setOpenedId,
  trf,
  setTrf,
}) {
  // const [removedFavorites, setRemovedFavorites] = useState([]);
  const setProject = useSetRecoilState(projectState);
  const projects = useRecoilValue(projectState);
  const bootstrap = useRecoilValue(bootstrapState);
  const { width } = useWindowDimensions();
  const isCollapsed = useRecoilValue(collapsed);
  const audioPlayer = useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [seekValue, setSeekValue] = useState(0);
  const { hovered } = useHover();
  const [open, isOpen] = useState(false);
  const token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${
      process.env.REACT_APP_AUTH_USER_POOLWEB_CLIENT_ID
    }.${useRecoilValue(userState).Username}.idToken`
  );
  const getprojects = async (ep) => {
    try {
      const obj = ep && ep.length && ep.find((r) => r.name === "project");
      const path = obj && obj.paths.includes("/project") ? "/project" : "";
      const endpoint = obj && obj.endpoint;
      const response = await getProjects(token, path, endpoint);
      if (response && response.status === 200) {
        setProject(response.data);
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message:
            "Something went wrong in refreshing the projects, please refresh the page",
        });
      }
      return true;
    } catch (e) {
      showNotification({
        color: "red",
        title: "Error!",
        message:
          "Something went wrong in refreshing the projects, please refresh the page or try again",
      });
      return false;
    }
  };
  useEffect(() => {
    if (keyName !== playingIndex && playingIndex !== undefined) {
      audioPlayer.current.pause();
    }
  }, [playingIndex]);

  const play = () => {
    setPInd(keyName);
    audioPlayer.current.play();
  };
  const pause = () => {
    setPInd("");
    audioPlayer.current.pause();
  };
  const dotIcon = (
    <span>
      <Image
        src={dots}
        style={{
          height: width > 768 ? "50px" : "32px",
          width: width > 768 ? "50px" : "32px",
        }}
      />
    </span>
  );
  const removeAddFavourite = async (e) => {
    try {
      const dataSource = {
        mediaId: e,
      };
      const obj =
        bootstrap &&
        bootstrap.length &&
        bootstrap.find((r) => r.name === "favorite");
      const path =
        obj && obj.paths.includes("/favorite") ? "/favorite/sound" : "";
      const endpoint = obj && obj.endpoint;
      let response;

      if (soundLibrary && data && data.favorite) {
        response = await removeFavorite(token, dataSource, path, endpoint);
      } else if (soundLibrary && data && !data.favorite) {
        response = await addFavorite(token, dataSource, path, endpoint);
      } else {
        response = await removeFavorite(token, dataSource, path, endpoint);
      }
      if (response && response.status === 201) {
        // setRemovedFavorites((prevState) => [...prevState, e]);
        getF().then().catch();
        showNotification({
          color: "green",
          title: "Success!",
          message: soundLibrary
            ? data && data.favorite
              ? "Removed from favourites"
              : "Added to favourites"
            : "Removed from favourites",
        });
      } else if (response && response.status === 200) {
        showNotification({
          color: "green",
          title: "Success!",
          message: "Favorite already added",
        });
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Something went wrong, please try again",
        });
      }
    } catch (err) {
      showNotification({
        color: "red",
        title: "Error!",
        message: "Something went wrong , please try again",
      });
    }
  };
  // const stop = () => {
  //   audioPlayer.current.pause();
  //   audioPlayer.current.currentTime = 0;
  // };
  // const setSpeed = (speed) => {
  //   audioPlayer.current.playbackRate = speed;
  // };
  const onPlaying = () => {
    setSeekValue(
      (audioPlayer.current.currentTime / audioPlayer.current.duration) * 100
    );
    // if (audioPlayer.current.currentTime <= 121) {
    if (audioPlayer.current.duration === audioPlayer.current.currentTime) {
      setPInd("");
      audioPlayer.current.pause();
    } else {
      setCurrentTime(audioPlayer.current.currentTime);
    }
    // } else {
    //   setPInd("");
    //   audioPlayer.current.pause();
    // }
  };
  const soundfl = (
    <div style={{ display: "flex" }} className="dropSound">
      <Image className="detsnd" src={soundf} />
      <p className="soundflText">Sound folder</p>
      <Image className="dpd" src={navdrpdwnicn} />
    </div>
  );
  const detl = (
    <div style={{ display: "flex" }} className="dropSound">
      <Image className="detsnd" src={det} />
      <p className="soundflText">Details</p>
      <Image
        className={`dpd ${
          open && openedId === (data && data["objectKey"]) ? "rotet" : ""
        }`}
        src={navdrpdwnicn}
      />
    </div>
  );
  const openNotOpen = (objectKey) => {
    if ((!trf && !open && !openedId) || (!trf && open && !openedId)) {
      setOpenedId(objectKey);
      setTrf(true);
      isOpen(true);
    } else if (trf && open && openedId === objectKey) {
      setOpenedId("");
      setTrf(false);
      isOpen(false);
    } else if (trf && openedId !== objectKey) {
      if (!open) {
        setOpenedId(objectKey);
        setTrf(true);
      } else {
        setOpenedId(objectKey);
      }
    } else if (trf && openedId === objectKey) {
      if (!open) {
        setOpenedId("");
        setTrf(false);
        isOpen(false);
      }
    }
  };
  const pressSpaces = (e, value, id) => {
    if (e.key === " " || e.code === "Space" || e.keyCode === 32) {
      if (value === "first") {
        setMPD(data);
      } else if (value === "second") {
        setMPD(data);
      } else if (value === "play") {
        play();
      } else if (value === "pause") {
        pause();
      } else if (value === "remove") {
        removeAddFavourite(id);
      } else if (value === "addDetails") {
        openNotOpen(id);
      }
    }
    return true;
  };
  const setCreateProject = async (e) => {
    try {
      const obj =
        bootstrap &&
        bootstrap.length &&
        bootstrap.find((r) => r.name === "project");
      const path = obj && obj.paths.includes("/project") ? "/project" : "";
      const endpoint = obj && obj.endpoint;
      const response = await postProjects(token, e, path, endpoint);
      if (response && response.status === 201) {
        showNotification({
          color: "blue",
          title: "Success!",
          message: "Project added successfully",
        });
        getprojects(bootstrap).then().catch();
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Either project already exists or something went wrong",
        });
      }
    } catch (err) {
      showNotification({
        color: "red",
        title: "Error!",
        message:
          "Either project already exists or something went wrong or try again",
      });
      return false;
    }
  };
  const postexistingprojectfavorite = async (e, id) => {
    try {
      const dataSource = {
        mediaId: e,
      };
      const obj =
        bootstrap &&
        bootstrap.length &&
        bootstrap.find((r) => r.name === "project");
      const path =
        obj && obj.paths.includes("/project") ? `/project/${id}/sound` : "";
      const endpoint = obj && obj.endpoint;
      const response = await postExistingProjectFavorite(
        token,
        dataSource,
        path,
        endpoint
      );
      if (response && response.status === 201) {
        showNotification({
          color: "blue",
          title: "Success!",
          message: "Project added successfully",
        });
        return true;
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Either project already exists or something went wrong",
        });
        return false;
      }
    } catch (err) {
      showNotification({
        color: "red",
        title: "Error!",
        message: "Something went wrong. Please try again",
      });
      return false;
    }
  };
  const addToPj = (
    <span className="addToprjTtl">
      Add to Project
      <Image className="addTofl" src={addtoprjdpd} />
    </span>
  );
  if (data) {
    if (
      !type
      //  &&
      // (![...removedFavorites].includes(data.id) || !removedFavorites.length
      // )
    ) {
      return (
        <>
          <div
            style={{ display: "contents" }}
            onClick={() => {
              setMPD(data);
            }}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              pressSpaces(e, "first");
            }}
          >
            <div className="projectImageClass">
              <Image
                src={data && data.image ? data.image : projectListdefault}
                alt="Project Image"
                className={width > 768 ? "projectImages" : "projectImage1"}
              />
            </div>
            <div className={width > 768 ? "projectDetails" : "projectDetails1"}>
              <div className={width > 768 ? "projectNamess" : "projectName1"}>
                {data.name.length > 10
                  ? `${data.name.substring(0, 10)}...`
                  : data.name}
              </div>
              <div className={width > 768 ? "projectTitles" : "projectTitle1"}>
                {data.collection}
              </div>
            </div>
            {width > 768 && (
              <>
                <div className="musicCtrls__play">
                  <div className={width > 768 ? "mp" : "mp1"}>
                    <audio
                      src={`${data.signedUrl}`}
                      ref={audioPlayer}
                      onTimeUpdate={onPlaying}
                    >
                      Your browser does not support the
                      <code>audio</code> element.
                    </audio>
                    <div className="timeDiv">
                      <p className="times" style={{ color: "#fff" }}>
                        {currentTime.toFixed(0)}
                      </p>
                      <p className="times" style={{ color: "#fff" }}>
                        &nbsp;/&nbsp; {121 - currentTime.toFixed(0)}
                      </p>
                    </div>
                  </div>
                  <div className={width > 768 ? "musicPlayer" : "musicPlayer1"}>
                    {width > 768 && (
                      <>
                        <div>
                          <Image
                            onClick={() => {
                              play();
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => pressSpaces(e, "play")}
                            src={ple}
                            alt="Play"
                            className={
                              keyName !== playingIndex
                                ? "showPlayPauses1"
                                : "noPlayPause"
                            }
                          />
                          <Image
                            src={paas}
                            alt="Pause"
                            onClick={() => {
                              pause();
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => pressSpaces(e, "pause")}
                            className={
                              keyName === playingIndex
                                ? "showPlayPauses1"
                                : "noPlayPause"
                            }
                          />
                          {/* <button onClick={stop}>stop</button> */}
                          {/* <button onClick={() => setSpeed(0.5)}>0.5x</button>
            <button onClick={() => setSpeed(1)}>1x</button>
            <button onClick={() => setSpeed(1.5)}>1.5x</button>
            <button onClick={() => setSpeed(2)}>2x</button> */}
                        </div>
                        <Slider
                          id="slids"
                          value={seekValue}
                          styles={{
                            thumb: {
                              transition: "opacity 150ms ease",
                              opacity: hovered ? 1 : 0,
                            },

                            dragging: {
                              opacity: 1,
                            },
                          }}
                          onChange={(e) => {
                            const seekto =
                              audioPlayer.current.duration * (+e / 100);
                            audioPlayer.current.currentTime = seekto;
                            setSeekValue(e);
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="fav"
                  role="button"
                  onClick={() => {
                    removeAddFavourite(data.id);
                  }}
                  tabIndex={0}
                  onKeyPress={(e) => pressSpaces(e, "remove", data.id)}
                >
                  <Image
                    src={
                      (data && data.favorite) || !soundLibrary
                        ? filledfavourite
                        : favourite
                    }
                    alt="filledfavourite"
                    className="favLike"
                  />
                </div>

                <div className="projectDrpDwns">
                  <NavDropdown
                    align="start"
                    title={addToPj}
                    className="addToprj"
                    onClick={() => {
                      // isOpen(false);
                      setOpenedId(
                        data && data["objectKey"] && data["objectKey"]
                      );
                      isOpen(!open);
                    }}
                  >
                    <NavDropdown.Item className="insideNavButton mt-2 addToPjBtn">
                      <AddNewProject
                        mediaId={data.id}
                        setCreateProject={(e) => setCreateProject(e)}
                      />
                    </NavDropdown.Item>
                    {projects &&
                      projects.projects &&
                      projects.projects.length &&
                      projects.projects.map((project) => {
                        return (
                          <NavDropdown.Item
                            key={project.id}
                            className="insideNavButton mt-3"
                            onClick={() => {
                              postexistingprojectfavorite(data.id, project.id);
                            }}
                          >
                            {project.projectName}
                          </NavDropdown.Item>
                        );
                      })}
                  </NavDropdown>
                </div>
              </>
            )}
          </div>
          {width <= 425 && !isCollapsed && (
            <div className="dotsClass ms-auto">
              <NavDropdown align="start" title={dotIcon} className="dots">
                <NavDropdown.Item className="insideNavButton">
                  Download sound as code
                </NavDropdown.Item>
                <NavDropdown.Item className="insideNavButton">
                  Go to artist
                </NavDropdown.Item>
                <NavDropdown.Item className="insideNavButton">
                  Go to album
                </NavDropdown.Item>
                <NavDropdown.Item className="insideNavButton">
                  Share
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          )}
          {width > 425 && (
            <div className="dotsClass ms-auto">
              <NavDropdown align="start" title={dotIcon} className="dots">
                <NavDropdown.Item className="insideNavButton">
                  Download sound as code
                </NavDropdown.Item>
                <NavDropdown.Item className="insideNavButton">
                  Go to artist
                </NavDropdown.Item>
                <NavDropdown.Item className="insideNavButton">
                  Go to album
                </NavDropdown.Item>
                <NavDropdown.Item className="insideNavButton">
                  Share
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          )}
        </>
      );
    } else {
      return (
        <div
          onClick={() => {
            setMPD(data);
          }}
          className="mpd"
          role="button"
          tabIndex={0}
          onKeyPress={(e) => pressSpaces(e, "first")}
        >
          <div className="projectImageClass">
            <Image
              src={data && data.image ? data.image : projectListdefault}
              alt="Project Image"
              className={width > 768 ? "projectImages" : "projectImage1"}
            />
          </div>
          <div className={width > 768 ? "projectDetails" : "projectDetails1"}>
            <div className={width > 768 ? "projectNamess" : "projectName1"}>
              {data && data.name && data.name.length > 10
                ? `${data.name.substring(0, 10)}...`
                : data.name}
            </div>
            <div className={width > 768 ? "projectTitles" : "projectTitle1"}>
              {data && data.collection && data.collection}
            </div>
          </div>
          {width > 768 && (
            <>
              <div className="musicCtrls__play">
                <div className={width > 768 ? "mp" : "mp1"}>
                  <audio
                    src={data.signedUrl}
                    ref={audioPlayer}
                    onTimeUpdate={onPlaying}
                  >
                    Your browser does not support the
                    <code>audio</code> element.
                  </audio>
                  <div className="timeDiv">
                    <p className="times" style={{ color: "#fff" }}>
                      {currentTime.toFixed(0)}
                    </p>
                    <p className="times" style={{ color: "#fff" }}>
                      &nbsp;/&nbsp; {121 - currentTime.toFixed(0)}
                    </p>
                  </div>
                </div>
                <div className={width > 768 ? "musicPlayer" : "musicPlayer1"}>
                  {width > 768 && (
                    <>
                      <div>
                        <Image
                          onClick={() => {
                            play();
                          }}
                          role="button"
                          tabIndex={0}
                          onKeyPress={(e) => pressSpaces(e, "play")}
                          src={ple}
                          alt="Play"
                          className={
                            keyName !== playingIndex
                              ? "showPlayPauses1"
                              : "noPlayPause"
                          }
                        />
                        <Image
                          src={paas}
                          alt="Pause"
                          onClick={() => {
                            pause();
                          }}
                          role="button"
                          tabIndex={0}
                          onKeyPress={(e) => pressSpaces(e, "pause")}
                          className={
                            keyName === playingIndex
                              ? "showPlayPauses1"
                              : "noPlayPause"
                          }
                        />
                      </div>
                      <Slider
                        id="slids"
                        value={seekValue}
                        styles={{
                          thumb: {
                            transition: "opacity 150ms ease",
                            opacity: hovered ? 1 : 0,
                          },

                          dragging: {
                            opacity: 1,
                          },
                        }}
                        onChange={(e) => {
                          const seekto =
                            audioPlayer.current.duration * (+e / 100);
                          audioPlayer.current.currentTime = seekto;
                          setSeekValue(e);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="vtline" />
              <div
                onClick={() => {
                  // isOpen(false);
                  if (data && data["objectKey"]) {
                    openNotOpen(data["objectKey"]);
                  }
                }}
                onKeyPress={() =>
                  pressSpaces(
                    null,
                    "addDetails",
                    data && data["objectKey"] && data["objectKey"]
                  )
                }
                tabIndex={0}
                role="button"
              >
                {detl}
              </div>
              <div className="vtline" />
              <div>{soundfl}</div>
            </>
          )}
          {width <= 425 && !isCollapsed && (
            <div className="dotsClass ms-auto">
              <NavDropdown align="start" title={dotIcon} className="dots">
                <NavDropdown.Item className="insideNavButton">
                  Download sound as code
                </NavDropdown.Item>
                <NavDropdown.Item className="insideNavButton">
                  Go to artist
                </NavDropdown.Item>
                <NavDropdown.Item className="insideNavButton">
                  Go to album
                </NavDropdown.Item>
                <NavDropdown.Item className="insideNavButton">
                  Share
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          )}
          {width > 425 && (
            <div className="dotsClass ms-auto">
              <NavDropdown align="start" title={dotIcon} className="dots">
                <NavDropdown.Item className="insideNavButton">
                  Download sound as code
                </NavDropdown.Item>
                <NavDropdown.Item className="insideNavButton">
                  Go to artist
                </NavDropdown.Item>
                <NavDropdown.Item className="insideNavButton">
                  Go to album
                </NavDropdown.Item>
                <NavDropdown.Item className="insideNavButton">
                  Share
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          )}
        </div>
      );
    }
  } else return "No data";
}

export default MusicPlayer;
