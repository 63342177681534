/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState, useEffect } from "react";
import "./MobileMusicPlayer.css";
import { Slider } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { Image, NavDropdown } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import paas from "../../../../assets/pause.png";
import ple from "../../../../assets/play.png";
import cancelMusic from "../../../../assets/cancelMusic.png";
import projectListDefault from "../../../../assets/projectListdefault.png";
import dots from "../../../../assets/dots.png";
import { collapsed } from "../../../../states/collapseNav";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";

function MobileMusicPlayer({ data, setData, type }) {
  const { width } = useWindowDimensions();
  const isCollapsed = useRecoilValue(collapsed);
  const firstRender = useRef(true);
  const audioPlayer = useRef();
  const [seekValue, setSeekValue] = useState(0);
  const { hovered } = useHover();
  const [playing, setPlaying] = useState(false);
  // let [music,setMusic] = useState(data);
  const dotIcon = (
    <span>
      <Image
        src={dots}
        style={{
          height: "32px",
          width: "32px",
        }}
      />
    </span>
  );
  const play = () => {
    setPlaying(true);
    audioPlayer.current.play();
  };
  const pause = (datas) => {
    setPlaying(false);
    audioPlayer.current.pause();
    if (datas === "cancel") {
      setData();
    }
  };
  const onPlaying = () => {
    // if (audioPlayer.current.currentTime <= 121) {
    setSeekValue(
      (audioPlayer.current.currentTime / audioPlayer.current.duration) * 100
    );
    // } else {
    //   audioPlayer.current.pause();
    // }
  };
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setPlaying(true);
      audioPlayer.current.play();
    }
  }, [data]);
  const pressSpace = (e, value) => {
    if (e.key === " " || e.code === "Space" || e.keyCode === 32) {
      if (value === "first") {
        pause("cancel");
      }
    }
    return true;
  };
  if (!type) {
    return (
      <div
        className={`${width <= 425 && isCollapsed ? "visibleOrNot" : ""} ${
          width > 425 && width <= 768 && isCollapsed
            ? "mobilePlayerss"
            : "mobilePlayer"
        } mt-2`}
      >
        <div
          className={
            width > 425 ? (isCollapsed ? "addMargin" : "dontAddMargin") : ""
          }
        >
          <div style={{ display: "flex" }}>
            <audio
              src={data && `${data.signedUrl}`}
              ref={audioPlayer}
              onTimeUpdate={onPlaying}
            >
              Your browser does not support the
              <code>audio</code> element.
            </audio>
            <div className="projectImageClassMobile">
              <Image
                src={projectListDefault}
                alt="Project Image"
                className="projectImage"
              />
            </div>
            <div className="projectDetails">
              <p className="projectName">
                {data && data.name.length > 10
                  ? `${data.name.substring(0, 10)}...`
                  : data.name}
              </p>
              <p className="projectTitle">{data && data.collection}</p>
            </div>
            <div
              className="cancelPlay ms-auto"
              onClick={() => {
                pause("cancel");
              }}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => pressSpace(e, "first")}
            >
              <Image src={cancelMusic} alt="cancel" />
            </div>
          </div>
          <div style={{ display: "flex" }} className="justify-content-center">
            <div>
              <Image
                onClick={() => {
                  play();
                }}
                src={ple}
                alt="Play"
                className={playing ? "noPlayPause" : "showPlayPause1"}
              />
              <Image
                src={paas}
                alt="Pause"
                onClick={() => {
                  pause();
                }}
                className={playing ? "showPlayPause1" : "noPlayPause"}
              />
            </div>
            <div>
              <div className="dotsClass">
                <NavDropdown align="start" title={dotIcon} className="dotss">
                  <NavDropdown.Item className="insideNavButton">
                    Add to Project
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
          </div>
          <div>
            <Slider
              id="slid"
              value={seekValue}
              styles={{
                thumb: {
                  transition: "opacity 150ms ease",
                  opacity: hovered ? 1 : 0,
                },

                dragging: {
                  opacity: 1,
                },
              }}
              onChange={(e) => {
                const seekto = audioPlayer.current.duration * (+e / 100);
                audioPlayer.current.currentTime = seekto;
                setSeekValue(e);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`${
        width <= 425 && isCollapsed ? "visibleOrNot" : ""
      } mobilePlayer1 mt-2`}
    >
      <div
        className={
          width > 425 ? (isCollapsed ? "addMargin" : "dontAddMargin") : ""
        }
      >
        <div style={{ display: "flex" }}>
          <audio
            src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
            ref={audioPlayer}
            onTimeUpdate={onPlaying}
          >
            Your browser does not support the
            <code>audio</code> element.
          </audio>
          <div className="projectImageClassMobile">
            <Image
              src={projectListDefault}
              alt="Project Image"
              className="projectImage"
            />
          </div>
          <div className="projectDetails">
            <p className="projectName">
              {data && data.name && data.name.length > 10
                ? `${data.name.substring(0, 10)}...`
                : data.name}
            </p>
            <p className="projectTitle">
              {data && data.collection && data.collection}
            </p>
          </div>
          <div
            className="cancelPlay ms-auto"
            onClick={() => {
              pause("cancel");
            }}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => pressSpace(e, "first")}
          >
            <Image src={cancelMusic} alt="cancel" />
          </div>
        </div>
        <div style={{ display: "flex" }} className="justify-content-center">
          <div>
            <Image
              onClick={() => {
                play();
              }}
              src={ple}
              alt="Play"
              className={playing ? "noPlayPause" : "showPlayPause1"}
            />
            <Image
              src={paas}
              alt="Pause"
              onClick={() => {
                pause();
              }}
              className={playing ? "showPlayPause1" : "noPlayPause"}
            />
          </div>
          <div>
            <div className="dotsClass">
              <NavDropdown align="start" title={dotIcon} className="dotss">
                <NavDropdown.Item className="insideNavButton">
                  Add to Project
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
        <div>
          <Slider
            id="slid"
            value={seekValue}
            styles={{
              thumb: {
                transition: "opacity 150ms ease",
                opacity: hovered ? 1 : 0,
              },

              dragging: {
                opacity: 1,
              },
            }}
            onChange={(e) => {
              const seekto = audioPlayer.current.duration * (+e / 100);
              audioPlayer.current.currentTime = seekto;
              setSeekValue(e);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default MobileMusicPlayer;
