import React, { useState } from "react";
import "./Settings.css";
import { useRecoilValue } from "recoil";
import RenderSettings from "./RenderSettings";
import { collapsed } from "../../../states/collapseNav";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";

function Settings() {
  const expanded = useRecoilValue(collapsed);
  const { width } = useWindowDimensions();
  const [names, setNames] = useState("Personal");
  const pressSpace = (e, value) => {
    if (e.key === " " || e.code === "Space" || e.keyCode === 32) {
      if (value === "first") {
        setNames("Personal");
      } else if (value === "second") {
        setNames("Account");
      } else if (value === "third") {
        setNames("Notifications");
      } else if (value === "fourth") {
        setNames("Notifications");
      } else if (value === "fifth") {
        setNames("Privacy");
      }
    }
    return true;
  };
  return (
    <div
      className={`mt-5 ${width <= 768 ? "settingsClass1" : "settingsClass"}`}
    >
      <p className="headerText">Settings</p>
      <div className={width > 425 ? "settingContent" : "settingContent1"}>
        <div className="links">
          <div
            className="linkItems"
            onClick={() => {
              setNames("Personal");
            }}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => pressSpace(e, "first")}
          >
            <p className="linkText">Personal information</p>
            <div className="linkLine">
              <div
                className={names === "Personal" ? "lineLink" : "lineLink1"}
              />
            </div>
          </div>
          <div
            className="linkItems"
            onClick={() => {
              setNames("Account");
            }}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => pressSpace(e, "second")}
          >
            <p className="linkText">Account management</p>
            <div className="linkLine">
              <div className={names === "Account" ? "lineLink" : "lineLink1"} />
            </div>
          </div>
          <div
            className="linkItems"
            onClick={() => {
              setNames("Notifications");
            }}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => pressSpace(e, "third")}
          >
            <p className="linkText">Notifications</p>
            <div className="linkLine">
              <div
                className={names === "Notifications" ? "lineLink" : "lineLink1"}
              />
            </div>
          </div>
          <div
            className="linkItems"
            onClick={() => {
              setNames("Privacy");
            }}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => pressSpace(e, "fifth")}
          >
            <p className="linkText">Privacy and data</p>
            <div className="linkLine">
              <div className={names === "Privacy" ? "lineLink" : "lineLink1"} />
            </div>
          </div>
        </div>
        <div className={expanded ? "personalDetails1" : "personalDetails"}>
          <RenderSettings name={names} />
        </div>
      </div>
    </div>
  );
}

export default Settings;
