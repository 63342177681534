import React, { useState, useEffect, Fragment } from "react";
import "./FavoriteContents.css";
// import { useRecoilValue } from "recoil";
import MusicPlayer from "../components/MusicPlayer";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import MobileMusicPlayer from "../components/MobileMusicPlayer";
import ProjectMusicDetails from "../ProjectComponents/ProjectMusicDetails";
// import { folderId } from "../../../../states/folderId";

function FavoriteContents({ filter, datas, type, getF, soundLibrary }) {
  // const fId = useRecoilValue(folderId);
  const [playingIndex, setPlayingIndex] = useState();
  const { width } = useWindowDimensions();
  const [mobilePlayingData, setMobilePlayingData] = useState("");
  const [openedId, setOpenedId] = useState("");
  const [trf, setTrf] = useState(false);
  useEffect(() => {
    setMobilePlayingData("");
  }, [filter]);
  return (
    <>
      <div className={width <= 768 ? (!type ? "mPlayer" : "mPlayer1") : ""}>
        {!type
          ? filter === "Alphabetical"
            ? datas
                .sort((a, b) => {
                  return a.mediaLibraryData.name > b.mediaLibraryData.name
                    ? 1
                    : a === b
                    ? 0
                    : -1;
                })
                .map((data, index) => {
                  return (
                    <div
                      className="favoriteContents1 mb-4  ps-4"
                      // key={data["objectKey"]}
                      key={data.mediaId}
                    >
                      <MusicPlayer
                        data={data.mediaLibraryData}
                        keyName={index}
                        setPInd={(id) => setPlayingIndex(id)}
                        playingIndex={playingIndex}
                        setMPD={(det) => setMobilePlayingData(det)}
                        getF={getF}
                        openedId={openedId}
                        setOpenedId={(id) => setOpenedId(id)}
                      />
                    </div>
                  );
                })
            : filter === "Recently added"
            ? datas
                .sort((a, b) => {
                  return a.createdAt * 1000 < b.createdAt * 1000
                    ? 1
                    : a === b
                    ? 0
                    : -1;
                })
                .map((data, index) => {
                  return (
                    <div
                      className="favoriteContents1 mb-4  ps-4"
                      // key={data["objectKey"]}
                      key={data.mediaId}
                    >
                      <MusicPlayer
                        data={data.mediaLibraryData}
                        keyName={index}
                        setPInd={(id) => setPlayingIndex(id)}
                        playingIndex={playingIndex}
                        setMPD={(det) => setMobilePlayingData(det)}
                        getF={getF}
                        openedId={openedId}
                        setOpenedId={(id) => setOpenedId(id)}
                      />
                    </div>
                  );
                })
            : filter === "Artist"
            ? datas
                .sort((a, b) => {
                  return (
                    a.mediaLibraryData.creator - b.mediaLibraryData.creator
                  );
                })
                .map((data, index) => {
                  return (
                    <div
                      className="favoriteContents1 mb-4  ps-4"
                      // key={data["objectKey"]}
                      key={data.mediaId}
                    >
                      <MusicPlayer
                        data={data.mediaLibraryData}
                        keyName={index}
                        setPInd={(id) => setPlayingIndex(id)}
                        playingIndex={playingIndex}
                        setMPD={(det) => setMobilePlayingData(det)}
                        getF={getF}
                        openedId={openedId}
                        setOpenedId={(id) => setOpenedId(id)}
                      />
                    </div>
                  );
                })
            : filter === "Collection"
            ? datas
                .sort((a, b) => {
                  return a.mediaLibraryData.collection.localeCompare(
                    b.mediaLibraryData.collection
                  );
                })
                .map((data, index) => {
                  return (
                    <div
                      className="favoriteContents1 mb-4  ps-4"
                      // key={data["objectKey"]}
                      key={data.mediaId}
                    >
                      <MusicPlayer
                        data={data.mediaLibraryData}
                        keyName={index}
                        setPInd={(id) => setPlayingIndex(id)}
                        playingIndex={playingIndex}
                        setMPD={(det) => setMobilePlayingData(det)}
                        getF={getF}
                        openedId={openedId}
                        setOpenedId={(id) => setOpenedId(id)}
                      />
                    </div>
                  );
                })
            : !filter && soundLibrary
            ? datas &&
              datas["soundLibrary"] &&
              Array.isArray(datas["soundLibrary"]) &&
              datas["soundLibrary"].length &&
              datas["soundLibrary"].map((data, index) => {
                return (
                  <div
                    className="favoriteContents1 mb-4  ps-4"
                    // key={data["objectKey"]}
                    key={data.mediaId}
                  >
                    <MusicPlayer
                      data={data.mediaLibraryData}
                      keyName={index}
                      setPInd={(id) => setPlayingIndex(id)}
                      playingIndex={playingIndex}
                      setMPD={(det) => setMobilePlayingData(det)}
                      getF={getF}
                      soundLibrary
                      openedId={openedId}
                      setOpenedId={(id) => setOpenedId(id)}
                    />
                  </div>
                );
              })
            : null
          : Array.isArray(datas) &&
            datas.length &&
            datas.map((data, index) => {
              // if (!fId.length) {
              return (
                <Fragment key={data.id}>
                  <div className="musicBar">
                    {width > 768 && (
                      <div className="activeOrInactive">
                        <p className="activeText">Active</p>
                      </div>
                    )}
                    <div
                      className="favoriteContents mb-4  ps-4"
                      // key={data["objectKey"]}
                    >
                      <MusicPlayer
                        data={data.mediaLibraryData}
                        keyName={index}
                        setPInd={(id) => setPlayingIndex(id)}
                        playingIndex={playingIndex}
                        setMPD={(dat) => setMobilePlayingData(dat)}
                        type={type}
                        setOpenedId={(id) => {
                          setOpenedId(id);
                        }}
                        openedId={openedId}
                        setTrf={(tf) => setTrf(tf)}
                        trf={trf}
                      />
                    </div>
                  </div>
                  {trf &&
                    data &&
                    data.mediaLibraryData &&
                    data["mediaLibraryData"]["objectKey"] &&
                    data["mediaLibraryData"]["objectKey"] === openedId &&
                    openedId && (
                      <div className="ProjectMusicDiv">
                        <div className="emptyDiv"> </div>
                        <div className="ProjectMusic">
                          <ProjectMusicDetails />
                        </div>
                      </div>
                    )}
                </Fragment>
              );
              // } else if (fId.length && fId.includes(data.soundFolderId)) {
              //   return (
              //     <div className="musicBar" key={data.id}>
              //       {width > 768 && (
              //         <div className="activeOrInactive">
              //           <p className="activeText">Active</p>
              //         </div>
              //       )}
              //       <div
              //         className="favoriteContents mb-4  ps-4"
              //         // key={data["objectKey"]}
              //       >
              //         <MusicPlayer
              //           data={data.mediaLibraryData}
              //           keyName={index}
              //           setPInd={(id) => setPlayingIndex(id)}
              //           playingIndex={playingIndex}
              //           setMPD={(dta) => setMobilePlayingData(dta)}
              //           type={type}
              //         />
              //       </div>
              //     </div>
              //   );
              // }
            })}
      </div>
      <div className={!type ? "" : "mobileMusicPlayerDiv"}>
        {width < 769 ? (
          <MobileMusicPlayer
            data={mobilePlayingData}
            setData={() => setMobilePlayingData("")}
            type={type}
          />
        ) : null}
      </div>
    </>
  );
}

export default FavoriteContents;
