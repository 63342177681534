import React, { useEffect, useRef, useState } from "react";
import "./HomePage.css";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import { isSigned } from "../states/user";
import downarrowhome from "../assets/downarrowhome.png";
import music from "../assets/music.png";
import search from "../assets/search.png";
import braces from "../assets/braces.png";
import dashboardImage1 from "../assets/dashboardImage1.png";
import arrowhowitworks from "../assets/arrowhowitworks.png";
import rotated from "../assets/rotated.png";
import nevermiss from "../assets/nevermiss.png";
import frequentdd from "../assets/frequentdd.png";
import useWindowDimensions from "../customHooks/useWindowDimensions";
import WorkSliderComponent from "../components/WorkSliderComponent";
import GetInTouch from "../components/GetInTouch";
import HomeFooter from "../components/HomeFooter";

function HomePage() {
  const { width } = useWindowDimensions();
  const [applystyles, setApplystyles] = useState(false);
  const trackButton = useRef();
  const signed = useRecoilValue(isSigned);
  const navigate = useNavigate();
  useEffect(() => {
    if ((signed === true || signed === null) && localStorage.length === 7) {
      navigate("/console");
    } else if (
      ((signed === true || signed === null) && localStorage.length !== 7) ||
      signed === false
    ) {
      navigate("/");
    } else {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setApplystyles(true);
    }, 1000);
    return () => {
      setApplystyles(false);
    };
  }, []);
  return (
    <div className="home">
      <div className={width <= 768 ? "homeContents1" : "homeContents"}>
        <div className="textHeadings">
          <div className="bannerText">
            Design sound experiences <br />
            for e-commerce with ease <br />
            together with us
          </div>
          <p className="afterLine">
            Try our <span style={{ fontWeight: 700 }}>Sound platform </span>
            today, gain access to our <br />
            soundbank for e-commerce and enjoy thousands of <br />
            sounds, ambiances, and tunes.
          </p>
          <button type="button" className="trialButton">
            Start a free trial
          </button>
        </div>
        {applystyles && (
          <div className="dbImageClass">
            <Image src={dashboardImage1} className="dbImage" />
          </div>
        )}
      </div>
      <div className="arrowDiv">
        <Image
          src={downarrowhome}
          alt="explore more"
          className="downArrow"
          onClick={() => {
            trackButton.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }}
        />
      </div>
      <div className="howItWorks" ref={trackButton}>
        <div className="howItWorksDiv">
          <p className="howItWorksText">How it works</p>
        </div>
        <div className="howItWorksContentsDiv">
          <div className="first">
            <div className="howItWorksImageDiv">
              <Image src={music} alt="music" className="howItWorksImage" />
            </div>
            <div className="headingHow">
              <p className="headingText">Sign up</p>
            </div>
            <div className="bodyHow">
              <p className="bodyText">
                By signing up, you&#39;ll have access to
                <br /> thousands of sounds, ambiances
                <br /> and tunes
              </p>
            </div>
          </div>
          <div className="second">
            <Image
              src={width <= 1024 ? rotated : arrowhowitworks}
              alt="arrow"
              className="rightArrowHow"
            />
          </div>
          <div className="third">
            <div className="howItWorksImageDiv">
              <Image src={search} alt="search" className="howItWorksImage1" />
            </div>
            <div className="headingHow">
              <p className="headingText">Assemble</p>
            </div>
            <div className="bodyHow">
              <p className="bodyText">
                Search and find the right sound for
                <br /> your projects and organize them to
                <br /> your liking
              </p>
            </div>
          </div>
          <div className="fourth">
            <Image
              src={width <= 1024 ? rotated : arrowhowitworks}
              alt="arrow"
              className="rightArrowHow"
            />
          </div>
          <div className="fifth">
            <div className="howItWorksImageDiv">
              <Image src={braces} alt="braces" className="howItWorksImage2" />
            </div>
            <div className="headingHow">
              <p className="headingText">Implement</p>
            </div>
            <div className="bodyHow">
              <p className="bodyText">
                Implement sound onto your <br />
                website with ease by downloading
                <br />
                ready-to-use code for your sounds
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="neverMiss">
        <div className="nevermissImageDiv">
          <Image
            src={nevermiss}
            alt="neverMissImage"
            className="neverMissImage"
          />
        </div>
        <div className="neverMissContentDiv">
          <p className="neverMissHeading">
            Never miss a beat and
            <br /> keep your deadlines
          </p>
          <p className="neverMissContent">
            Our seamless smart-device site is fully integrated into
            <br /> our system. Keep a check on your projects on the go
            <br /> and create new sound experiences from anywhere
            <br /> and at a moment&#39;s notice.
          </p>
        </div>
      </div>
      <div className="frequent">
        <p className="frequentHeading">Frequently asked questions</p>
        <div className="frequentQuestions">
          <div className="firstFrequent">
            <div className="frequentLine"> </div>
            <div className="questionDiv">
              <p className="frequentText">
                How does Soniq’s sound platform work?
              </p>
              <Image src={frequentdd} alt="dropdown" className="frequentdd" />
            </div>
            <div className="frequentLine"> </div>
          </div>
          <div className="secondFrequent">
            <div className="frequentLine"> </div>
            <div className="questionDiv">
              <p className="frequentText">How many projects can I create?</p>
              <Image src={frequentdd} alt="dropdown" className="frequentdd" />
            </div>
            <div className="frequentLine"> </div>
          </div>
          <div className="secondFrequent">
            <div className="frequentLine"> </div>
            <div className="questionDiv">
              <p className="frequentText">
                May I use Soniq’s sound collection for my website?
              </p>
              <Image src={frequentdd} alt="dropdown" className="frequentdd" />
            </div>
            <div className="frequentLine"> </div>
          </div>
          <div className="secondFrequent">
            <div className="frequentLine"> </div>
            <div className="questionDiv">
              <p className="frequentText">Does Soniq have an app?</p>
              <Image src={frequentdd} alt="dropdown" className="frequentdd" />
            </div>
            <div className="frequentLine"> </div>
          </div>
          <div className="secondFrequent">
            <div className="frequentLine"> </div>
            <div className="questionDiv">
              <p className="frequentText">
                Can I cancel my account at any time?
              </p>
              <Image src={frequentdd} alt="dropdown" className="frequentdd" />
            </div>
            <div className="frequentLine"> </div>
          </div>
        </div>
      </div>
      <div className="staffs">
        <p className="staffHeading">We who work at Soniq</p>
        <WorkSliderComponent />
      </div>
      <div className="getInTouchForm text-white">
        <GetInTouch />
      </div>
      <div className="footer">
        <HomeFooter />
      </div>
    </div>
  );
}
export default HomePage;
