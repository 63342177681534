import React, { useState } from "react";
import { Image } from "react-bootstrap";
import favoriteSearch from "../../../../assets/favoriteSearch.png";
import "./SearchFavoriteComponent.css";

function SearchFavoriteComponent({
  toBeSearched,
  searchText,
  letsSetSearched,
}) {
  const [searchTexts, setSearchTexts] = useState("");
  const searchIt = () => {
    if (
      toBeSearched === "projects" ||
      toBeSearched === "soundLibrary" ||
      toBeSearched === "favorite"
    ) {
      letsSetSearched(searchTexts);
    }
  };
  const pressEnter = (e) => {
    if (e.key === "Enter") {
      if (
        toBeSearched === "projects" ||
        toBeSearched === "soundLibrary" ||
        toBeSearched === "favorite"
      ) {
        letsSetSearched(searchTexts);
      }
    }
    return true;
  };
  return (
    <div style={{ display: "flex" }}>
      <div className="searchFavoriteComponent">
        <input
          className="searchBox"
          type="text"
          placeholder={searchText}
          value={searchTexts}
          onChange={(e) => setSearchTexts(e.target.value)}
          onKeyPress={(e) => pressEnter(e)}
        />
        <Image
          src={favoriteSearch}
          className="searchIcon"
          onClick={() => searchIt()}
          alt="SearchIcon"
          tabIndex={0}
        />
      </div>
      {/* <div className="dropdownFilter">
        {width <= 425 && !isCollapsed && (
          <NavDropdown
            align="start"
            title={filterIcon}
            id="filterDrp"
            className="ms-auto me-4"
          >
            <NavDropdown.Item className="insideNavButton">
              Recently added
            </NavDropdown.Item>
            <NavDropdown.Item className="insideNavButton">
              Alphabetical
            </NavDropdown.Item>
            <NavDropdown.Item className="insideNavButton">
              Popular
            </NavDropdown.Item>
            <NavDropdown.Item className="insideNavButton">
              Artist
            </NavDropdown.Item>
            <NavDropdown.Item className="insideNavButton">
              Collection
            </NavDropdown.Item>
          </NavDropdown>
        )}
        {width > 425 && (
          <NavDropdown
            align="start"
            title={filterIcon}
            id="filterDrp"
            className="ms-auto me-4"
          >
            <NavDropdown.Item className="insideNavButton">
              Recently added
            </NavDropdown.Item>
            <NavDropdown.Item className="insideNavButton">
              Alphabetical
            </NavDropdown.Item>
            <NavDropdown.Item className="insideNavButton">
              Popular
            </NavDropdown.Item>
            <NavDropdown.Item className="insideNavButton">
              Artist
            </NavDropdown.Item>
            <NavDropdown.Item className="insideNavButton">
              Collection
            </NavDropdown.Item>
          </NavDropdown>
        )}
      </div> */}
    </div>
  );
}

export default SearchFavoriteComponent;
