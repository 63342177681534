import React from "react";
import { Image } from "react-bootstrap";
import plusIcon from "../../../../assets/plusIcon.png";
import "./ProjectMusicDetails.css";

function ProjectMusicDetails() {
  return (
    <div className="ProjectMusicDetails">
      <div className="firstRow">
        <div className="firstInput">
          <label className="soundUploadLabels" htmlFor="soundType">
            Sound type
          </label>
          <select id="soundType">
            <option value={null}>&emsp;-</option>
            <option value="UI SFX">&emsp;UI SFX</option>
            <option value="SFX">&emsp;SFX</option>
            <option value="Ambience">&emsp;Ambience</option>
            <option value="Music">&emsp;Music</option>
            <option value="Voice over">&emsp;Voice over</option>
          </select>
        </div>
        <div className="secondInput">
          <label className="soundUploadLabels" htmlFor="soundTitle">
            Sound title
          </label>
          <input type="text" id="soundTitle" placeholder="Type here" />
        </div>
        <div className="thirdInput">
          <label className="soundUploadLabels" htmlFor="soundInteraction">
            Sound interaction
          </label>
          <select id="soundInteraction">
            <option value={null}>&emsp;N/A</option>
            <option value="On click">&emsp;On click</option>
            <option value="On drag">&emsp;On drag</option>
            <option value="While hovering">&emsp;While hovering</option>
            <option value="While pressing">&emsp;While pressing</option>
            <option value="Key/gamepad">&emsp;Key/gamepad</option>
            <option value="Mouse enter">&emsp;Mouse enter</option>
            <option value="Mouse leave">&emsp;Mouse leave</option>
            <option value="Mouse down">&emsp;Mouse down</option>
            <option value="Mouse up">&emsp;Mouse up</option>
            <option value="Ongoing">&emsp;Ongoing</option>
          </select>
        </div>
      </div>
      <div className="secondRow">
        <div className="firstInput1">
          <label className="soundUploadLabels" htmlFor="note">
            Note
          </label>
          <textarea id="note" placeholder="Type here..." />
        </div>
        <div className="secondInput1">
          <label className="soundUploadLabels" htmlFor="image">
            Image
          </label>
          <div className="imageUploadInput">
            <input type="file" id="image" />
            <label className="uploadImageLabel" htmlFor="image">
              <p className="imageUploadText">Upload image</p>
              <Image className="plusIcon" alt="upload image" src={plusIcon} />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectMusicDetails;
