import React, { useEffect, useState } from "react";
import * as moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import "./LineChartComponent.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);
function LineChartComponent({ soundsHist }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 16,
            family: "Montserrat",
            weight: 500,
            style: "normal",
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 16,
            family: "Montserrat",
            weight: 500,
            style: "normal",
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 16,
            family: "Montserrat",
            weight: 500,
            style: "normal",
          },
        },
      },
    },
  };
  const [data, setData] = useState({});
  const drawChart = () => {
    const chartData = {
      labels: [],
      datasets: [],
    };
    const datasetsData = [];
    soundsHist["histogram"].forEach((r) => {
      const label =
        soundsHist.interval === "month" && moment(r["key"]).format("MMM");
      const value = r["count"];
      chartData["labels"].push(label);
      datasetsData.push(value);
    });
    const obj = {
      data: datasetsData,
      label: "Sound played/Month",
      fill: true,
      backgroundColor: "#20cab73b",
      borderColor: "#18D1B8",
      tension: 0.5,
    };
    chartData["datasets"][0] = obj;
    setData(chartData);
  };

  useEffect(() => {
    soundsHist &&
      Object.keys(soundsHist).length &&
      soundsHist["histogram"].length &&
      drawChart();
  }, [soundsHist]);
  return (
    <div id="lineChart">
      {data &&
        data.datasets &&
        data.labels &&
        data.datasets.length &&
        data.labels.length && <Line data={data} options={options} />}
    </div>
  );
}

export default LineChartComponent;
