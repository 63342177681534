import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "./SearchSlider.css";
import searchSoundLibrary from "../../../assets/searchSoundLibrary.png";
import cancelSearch from "../../../assets/cancelSearch.png";

function SearchSlider({ data, searchSliderSelected, setSearchSlider }) {
  const [dataChip, setDataChip] = useState("");
  useEffect(() => {
    const arrays = [];
    const size = 3;
    while (data && data.length > 0) arrays.push(data.splice(0, size));
    setDataChip(arrays);
  }, [data]);
  return (
    <Carousel indicators={false} interval={null}>
      {dataChip &&
        dataChip.length > 0 &&
        dataChip.map((e) => {
          return (
            <Carousel.Item key={e}>
              <div className="rowDiv">
                {e && e.length && e[0] && (
                  <div
                    className="colDiv"
                    onClick={() => {
                      setSearchSlider(e[0]);
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => setSearchSlider(e[0])}
                  >
                    <div
                      className={
                        Array.isArray(searchSliderSelected) &&
                        searchSliderSelected.length &&
                        searchSliderSelected.includes(e[0])
                          ? "itemDiv1"
                          : "itemDiv"
                      }
                    >
                      <div
                        className={
                          Array.isArray(searchSliderSelected) &&
                          searchSliderSelected.length &&
                          searchSliderSelected.includes(e[0])
                            ? "searchDiv1"
                            : "searchDiv"
                        }
                      >
                        <img
                          src={
                            Array.isArray(searchSliderSelected) &&
                            searchSliderSelected.length &&
                            searchSliderSelected.includes(e[0])
                              ? cancelSearch
                              : searchSoundLibrary
                          }
                          className={
                            Array.isArray(searchSliderSelected) &&
                            searchSliderSelected.length &&
                            searchSliderSelected.includes(e[0])
                              ? "cancelSearch"
                              : "searchSoundLibrary"
                          }
                          alt="searchCancelSoundLibrary"
                        />
                      </div>
                      <div
                        className={
                          Array.isArray(searchSliderSelected) &&
                          searchSliderSelected.length &&
                          searchSliderSelected.includes(e[0])
                            ? "searchDivText1"
                            : "searchDivText"
                        }
                      >
                        <p
                          className={
                            Array.isArray(searchSliderSelected) &&
                            searchSliderSelected.length &&
                            searchSliderSelected.includes(e[0])
                              ? "searchSliderText1"
                              : "searchSliderText"
                          }
                        >
                          {e[0]}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {e && e.length && e[1] && (
                  <div
                    className="colDiv"
                    onClick={() => {
                      setSearchSlider(e[1]);
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => setSearchSlider(e[1])}
                  >
                    <div
                      className={
                        Array.isArray(searchSliderSelected) &&
                        searchSliderSelected.length &&
                        searchSliderSelected.includes(e[1])
                          ? "itemDiv1"
                          : "itemDiv"
                      }
                    >
                      <div
                        className={
                          Array.isArray(searchSliderSelected) &&
                          searchSliderSelected.length &&
                          searchSliderSelected.includes(e[1])
                            ? "searchDiv1"
                            : "searchDiv"
                        }
                      >
                        <img
                          src={
                            Array.isArray(searchSliderSelected) &&
                            searchSliderSelected.length &&
                            searchSliderSelected.includes(e[1])
                              ? cancelSearch
                              : searchSoundLibrary
                          }
                          className={
                            Array.isArray(searchSliderSelected) &&
                            searchSliderSelected.length &&
                            searchSliderSelected.includes(e[1])
                              ? "cancelSearch"
                              : "searchSoundLibrary"
                          }
                          alt="searchCancelSoundLibrary"
                        />
                      </div>
                      <div
                        className={
                          Array.isArray(searchSliderSelected) &&
                          searchSliderSelected.length &&
                          searchSliderSelected.includes(e[1])
                            ? "searchDivText1"
                            : "searchDivText"
                        }
                      >
                        <p
                          className={
                            Array.isArray(searchSliderSelected) &&
                            searchSliderSelected.length &&
                            searchSliderSelected.includes(e[1])
                              ? "searchSliderText1"
                              : "searchSliderText"
                          }
                        >
                          {e[1]}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {e && e.length && e[2] && (
                  <div
                    className="colDiv"
                    onClick={() => {
                      setSearchSlider(e[2]);
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => setSearchSlider(e[2])}
                  >
                    <div
                      className={
                        Array.isArray(searchSliderSelected) &&
                        searchSliderSelected.length &&
                        searchSliderSelected.includes(e[2])
                          ? "itemDiv1"
                          : "itemDiv"
                      }
                    >
                      <div
                        className={
                          Array.isArray(searchSliderSelected) &&
                          searchSliderSelected.length &&
                          searchSliderSelected.includes(e[2])
                            ? "searchDiv1"
                            : "searchDiv"
                        }
                      >
                        <img
                          src={
                            Array.isArray(searchSliderSelected) &&
                            searchSliderSelected.length &&
                            searchSliderSelected.includes(e[2])
                              ? cancelSearch
                              : searchSoundLibrary
                          }
                          className={
                            Array.isArray(searchSliderSelected) &&
                            searchSliderSelected.length &&
                            searchSliderSelected.includes(e[2])
                              ? "cancelSearch"
                              : "searchSoundLibrary"
                          }
                          alt="searchCancelSoundLibrary"
                        />
                      </div>
                      <div
                        className={
                          Array.isArray(searchSliderSelected) &&
                          searchSliderSelected.length &&
                          searchSliderSelected.includes(e[2])
                            ? "searchDivText1"
                            : "searchDivText"
                        }
                      >
                        <p
                          className={
                            Array.isArray(searchSliderSelected) &&
                            searchSliderSelected.length &&
                            searchSliderSelected.includes(e[2])
                              ? "searchSliderText1"
                              : "searchSliderText"
                          }
                        >
                          {e[2]}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
}

export default SearchSlider;
