import React from "react";
import { Image } from "react-bootstrap";
import "./HomeFooter.css";
import logo from "../assets/soniclogo.png";

function HomeFooter() {
  return (
    <div className="HomeFooter">
      <div className="logoDiv">
        <Image src={logo} className="logosonic" alt="logo" />
        <p className="tagLine"> Sound as a service</p>
      </div>
      <div className="productDiv">
        <p className="mainText">Product</p>
        <p className="secondaryText">Features</p>
        <p className="secondaryText">Sound library</p>
      </div>
      <div className="pricingDiv">
        <p className="mainText">Pricing</p>
        <p className="secondaryText">Plans</p>
      </div>
      <div className="companyDiv">
        <p className="mainText">Company</p>
        <p className="secondaryText">Our story</p>
        <p className="secondaryText">Contact us</p>
        <p className="secondaryText">Careers</p>
        <p className="secondaryText">Privacy policy</p>
      </div>
      <div className="signUpDiv">
        <button className="signUpBtn" type="button">
          Sign up
        </button>
      </div>
    </div>
  );
}

export default HomeFooter;
