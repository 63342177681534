const { atom } = require("recoil");

const userState = atom({
  key: "user",
  default: {},
});
const isSigned = atom({
  key: "signedIn",
  default: null,
});
module.exports = { userState, isSigned };
