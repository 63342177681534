import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./ProjectList.css";
import { Row, Col } from "react-bootstrap";
import AddNewProject from "./AddNewProject";
import EachProject from "./EachProject";

function ProjectList({ list, filtered, pushInList }) {
  const [content, setContent] = useState([]);
  useEffect(() => {
    if (list && list.length) {
      let arr = [];
      let result = [];
      const sortedList = (a, b) =>
        a.projectName.localeCompare(b.projectName, "en", {
          numeric: true,
        });
      const sortEditedAt = (arrs, createOrUpdate, ascOrDec) => {
        if (ascOrDec === "asc") {
          return createOrUpdate === "create"
            ? [...arrs].sort(({ createdAt: a }, { createdAt: b }) => a - b)
            : [...arrs].sort(({ editedAt: a }, { editedAt: b }) => a - b);
        }
        return createOrUpdate === "create"
          ? [...arrs].sort(({ createdAt: a }, { createdAt: b }) => b - a)
          : [...arrs].sort(({ editedAt: a }, { editedAt: b }) => b - a);
      };
      if (filtered === "Name") {
        result = [...list].sort(sortedList);
      } else if (filtered === "Edited At") {
        result = sortEditedAt(list, "update", "asc");
      } else if (filtered === "Created At") {
        result = sortEditedAt(list, "create", "asc");
      }
      arr = [null, ...result];
      setContent(arr);
    }
  }, [filtered, list]);
  return (
    <div className="ProjectList">
      <Row xs={1} sm={2} md={2} lg={3} xl={4} xxl={4} className="rows">
        {!!(content && content.length) &&
          content.map((project, i) => {
            if (i === 0) {
              return (
                <Col
                  key={`${uuidv4()}${new Date().getTime()}`}
                  className="mt-3"
                >
                  <AddNewProject pushInList={pushInList} />
                </Col>
              );
            }
            return (
              <Col key={project.id} className="mt-3">
                <EachProject project={project} />
              </Col>
            );
          })}
      </Row>
    </div>
  );
}

export default ProjectList;
