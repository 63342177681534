import React from "react";
import "./CardComponent.css";

function CardComponent({ projects, totalSoundsPlayed, soundsUsed }) {
  const getNumberCount = (labelValue) => {
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(2)} B`
      : Math.abs(Number(labelValue)) >= 1.0e6
      ? `${Math.abs(Number(labelValue)) / 1.0e6} mil`
      : Math.abs(Number(labelValue)) >= 1.0e3
      ? `${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(2)} K`
      : Math.abs(Number(labelValue));
  };
  return (
    <div className="dashboardSection">
      <div className="dashboardCard">
        <div className="firstRowCard">
          <div className="cardContent">
            <div className="cardTitleDiv">
              <p className="cardTitle">Sounds in-use</p>
            </div>
            <div className="dashboardcardBody">
              <div className="numbersDiv">
                <p className="numbers">{soundsUsed && soundsUsed}</p>
              </div>
              <div className="radialBackgroundDiv bg1"> </div>
            </div>
          </div>
          <div className="cardContent">
            <div className="cardTitleDiv">
              <p className="cardTitle">Total sounds played</p>
            </div>
            <div className="dashboardcardBody">
              <div className="numbersDiv">
                <p className="numbers">
                  {totalSoundsPlayed && getNumberCount(totalSoundsPlayed)}
                </p>
              </div>
              <div className="radialBackgroundDiv bg2"> </div>
            </div>
          </div>
        </div>
        <div className="secondRowCard">
          <div className="cardContent">
            <div className="cardTitleDiv">
              <p className="cardTitle">Sounds saved</p>
            </div>
            <div className="dashboardcardBody">
              <div className="numbersDiv">
                <p className="numbers">{soundsUsed && soundsUsed}</p>
              </div>
              <div className="radialBackgroundDiv bg3"> </div>
            </div>
          </div>
          <div className="cardContent">
            <div className="cardTitleDiv">
              <p className="cardTitle">Projects</p>
            </div>
            <div className="dashboardcardBody">
              <div className="numbersDiv">
                <p className="numbers">{projects && projects}</p>
              </div>
              <div className="radialBackgroundDiv bg4"> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardComponent;
