import React, { useState } from "react";
import { Navbar, Nav, NavDropdown, Image, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { isSigned, userState } from "../states/user";
import logo from "../assets/soniclogo.png";
import profiledpd from "../assets/profiledpd.png";
import navdrpdwnicn from "../assets/navdrpdwnicn.png";
import notifications from "../assets/notifications.png";
import "./NavBar.css";

function NavBar() {
  const [expanded, setExpanded] = useState(false);
  const [rotates, setRotates] = useState(false);
  const [rotates1, setRotates1] = useState(false);
  const location = useLocation();
  const setIsSigned = useSetRecoilState(isSigned);
  const user = useRecoilValue(userState);
  // const [profileImage, setProfileImage] = useState("");
  const userName =
    user.UserAttributes?.find((e) => e.Name === "name")?.Value[0] || "";
  const profileImage = "";

  const drpuser = (
    <span style={{ display: "flex" }}>
      {profileImage && (
        <Image src={profileImage} className="photo" alt="User Photo" />
      )}
      {!profileImage && <div className="dpp">{userName}</div>}
      <Image src={profiledpd} alt="dropdown" className="profileImage" />
    </span>
  );
  const pric = (
    <div className="pric">
      <p className="pricingText">Pricing</p>
      <Image src={navdrpdwnicn} alt="dropdown" className="pricingImage" />
    </div>
  );
  return (
    <Navbar
      expand="lg"
      className={
        // (location.pathname === "/" && color ? "navStyles" : "navStyles1") +
        `${location.pathname === "/" ? "navStyles" : "navStyles1"} navbar`
      }
      expanded={expanded}
      // fixed
    >
      <Container>
        <Link to="/" className="textStyles" onClick={() => setExpanded(false)}>
          <img
            alt="hello"
            src={logo}
            className="d-inline-block align-top logoStyle"
          />
          <Navbar.Brand className="textStyles bname">
            Sound as a service
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto forNavCollapse">
            {!!(location.pathname === "/") && (
              <>
                <Nav.Link
                  as={Link}
                  to="/"
                  className="textStyles rightItems"
                  onClick={() => setExpanded(false)}
                >
                  Product
                </Nav.Link>
                <NavDropdown
                  align="end"
                  title={pric}
                  id="basic-nav-dropdown2"
                  onClick={() => {
                    setRotates(!rotates);
                  }}
                  className={`${
                    !rotates ? "basic-nav-dropdown" : "basic-nav-dropdown1"
                  } pricesDrop`}
                >
                  <NavDropdown.Item>Action</NavDropdown.Item>
                  <NavDropdown.Item>Another action</NavDropdown.Item>
                  <NavDropdown.Item>Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>Separated link</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  as={Link}
                  to="/"
                  className="textStyles rightItems"
                  onClick={() => setExpanded(false)}
                >
                  About
                </Nav.Link>
                <Nav.Link
                  className="textStyles rightItems"
                  as={Link}
                  to="/"
                  onClick={() => setExpanded(false)}
                >
                  Book Demo
                </Nav.Link>
                <Nav.Link
                  className="textStyles rightItems navButton text-center"
                  as={Link}
                  to="/console"
                  onClick={() => {
                    setIsSigned(true);
                    setExpanded(false);
                  }}
                >
                  Sign in
                </Nav.Link>
              </>
            )}
            {!!(location.pathname !== "/" && Object.keys(user).length) && (
              <>
                <Nav.Link
                  onClick={() => {
                    // console.log("clicked");
                  }}
                >
                  <Image
                    src={notifications}
                    className="notificationClass"
                    alt="notifications"
                  />
                  <div className="text-center notificationNumber">1</div>
                </Nav.Link>
                <NavDropdown
                  align="start"
                  title={drpuser}
                  id="basic-nav-dropdown"
                  onClick={() => {
                    setRotates1(!rotates1);
                  }}
                  className={
                    !rotates1 ? "basic-nav-dropdowns" : "basic-nav-dropdowns1"
                  }
                >
                  <NavDropdown.Item className="insideNavButton">
                    Profile
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item className="insideNavButton">
                    Terms of service
                  </NavDropdown.Item>
                  <NavDropdown.Item className="insideNavButton">
                    About
                  </NavDropdown.Item>
                  <NavDropdown.Item className="insideNavButton">
                    Language
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="insideNavButton"
                    onClick={() => {
                      setExpanded(false);
                      setIsSigned(false);
                    }}
                  >
                    Sign out
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
