import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./SideNav.css";
import { Image } from "react-bootstrap";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";
import vectorarrow from "../assets/vectorarrow.png";
import vectorarrowreverse from "../assets/vectorarrowreverse.png";
import dashboard from "../assets/dashboard.png";
import project from "../assets/project.png";
import sound from "../assets/sound.png";
import settings from "../assets/settings.png";
import favorite from "../assets/favourite.png";
import help from "../assets/help.png";
import { collapsed } from "../states/collapseNav";
import useWindowDimensions from "../customHooks/useWindowDimensions";
import cancel from "../assets/cancel.png";

function SideNav() {
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [arw, setArw] = useState(vectorarrow);
  // const setPage = (pageName) => {
  //   setPages(pageName);
  // };
  const navigateTo = (pageName) => {
    switch (pageName) {
      case "Dashboard":
        return navigate(`dashboard`);
      case "Sound":
        return navigate(`sound`);
      case "Help":
        return navigate(`help`);
      case "Settings":
        return navigate(`settings`);
      case "Favorites":
        return navigate(`favorites`);
      case "Projects":
        return navigate(`projects`);
      default:
        return navigate(`dashboard`);
    }
  };
  const setCollapse = useSetRecoilState(collapsed);
  const collapsedValue = useRecoilValue(collapsed);
  const pressSpace = (e, value, name) => {
    if (e.key === " " || e.code === "Space" || e.keyCode === 32) {
      if (value === "first") {
        setCollapse(!collapsedValue);
        arw === vectorarrow ? setArw(vectorarrowreverse) : setArw(vectorarrow);
      } else if (value === "second") {
        navigateTo(name);
      }
    }
    return true;
  };
  if (width > 425) {
    return (
      <div className={collapsedValue ? "sideNavs1" : "sideNavs"}>
        <div
          role="button"
          tabIndex={0}
          className={collapsedValue ? "sarrow" : "sarrow1"}
          onClick={() => {
            setCollapse(!collapsedValue);
          }}
          onKeyPress={(e) => pressSpace(e, "first")}
        >
          <Image
            src={collapsedValue ? vectorarrowreverse : vectorarrow}
            className="arrow-img"
          />
        </div>
        <div className={collapsedValue ? "navContents1" : "navContents"}>
          {[
            { name: "Dashboard", pic: dashboard },
            { name: "Sound", pic: sound },
            { name: "Projects", pic: project },
            { name: "Favorites", pic: favorite },
            { name: "Settings", pic: settings },
            { name: "Help", pic: help },
          ].map(({ name, pic }) => {
            return (
              <div
                role="button"
                className="navItems"
                key={`${uuidv4()}${new Date().getTime()}`}
                onClick={() => {
                  // setPage(name);
                  navigateTo(name);
                }}
                tabIndex={0}
                style={{ cursor: "pointer" }}
                onKeyPress={(e) => {
                  pressSpace(e, "second", name);
                }}
              >
                {!!(
                  location.pathname === "/console" && name === "Dashboard"
                ) && (
                  <div
                    className={
                      !collapsedValue ? "text-shaadow" : "text-shaadow1"
                    }
                  >
                    <div
                      style={{
                        width: "10px",
                        height: "100%",
                        background: "#18D1B8",
                      }}
                    />
                  </div>
                )}
                {!!location.pathname
                  .toLowerCase()
                  .includes(name.toLowerCase()) && (
                  <div
                    className={
                      !collapsedValue ? "text-shaadow" : "text-shaadow1"
                    }
                  >
                    <div
                      style={{
                        width: "10px",
                        height: "100%",
                        background: "#18D1B8",
                      }}
                    />
                  </div>
                )}
                {!(
                  location.pathname
                    .toLowerCase()
                    .includes(name.toLowerCase()) ||
                  (location.pathname === "/console" && name === "Dashboard")
                ) && (
                  <div
                    className={
                      !collapsedValue ? "text-shaadows" : "text-shaadows1"
                    }
                  />
                )}
                <div className="navPicDiv">
                  <Image className="navPic" alt={name} src={pic} />
                </div>
                {!collapsedValue && (
                  <div className="sideNavName">
                    <p className="navName">
                      {name === "Sound" ? `${name} library` : `${name}`}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className={collapsedValue ? "sideNavs1" : "sideNavs"}>
        <div
          className={collapsedValue ? "sarrow1" : "sarrow"}
          onClick={() => {
            setCollapse(!collapsedValue);
            arw === vectorarrow
              ? setArw(vectorarrowreverse)
              : setArw(vectorarrow);
          }}
          onKeyPress={(e) => pressSpace(e, "first")}
          role="button"
          tabIndex={0}
        >
          <Image
            src={!collapsedValue ? vectorarrow : cancel}
            className="arrow-img"
          />
        </div>
        <div className={collapsedValue ? "navContents" : "navContents1"}>
          {[
            { name: "Dashboard", pic: dashboard },
            { name: "Sound", pic: sound },
            { name: "Projects", pic: project },
            { name: "Settings", pic: settings },
            { name: "Favorites", pic: favorite },
            { name: "Help", pic: help },
          ].map(({ name, pic }) => (
            <div
              className="navItems"
              key={`${uuidv4()}${new Date().getTime()}`}
              onClick={() => {
                navigateTo(name);
                // setPage(name);
              }}
              style={{ cursor: "pointer" }}
              tabIndex={0}
              onKeyPress={(e) => pressSpace(e, "second", name)}
              role="button"
            >
              {!!(location.pathname === "/console" && name === "Dashboard") && (
                <div
                  className={!collapsedValue ? "text-shaadow" : "text-shaadow1"}
                >
                  <div
                    style={{
                      width: "5px",
                      height: "100%",
                      background: "#18D1B8",
                    }}
                  />
                </div>
              )}
              {!!location.pathname
                .toLowerCase()
                .includes(name.toLowerCase()) && (
                <div
                  className={!collapsedValue ? "text-shaadow" : "text-shaadow1"}
                >
                  <div
                    style={{
                      width: "5px",
                      height: "100%",
                      background: "#18D1B8",
                    }}
                  />
                </div>
              )}
              {!(
                location.pathname.toLowerCase().includes(name.toLowerCase()) ||
                (location.pathname === "/console" && name === "Dashboard")
              ) && (
                <div
                  className={
                    !collapsedValue ? "text-shaadows" : "text-shaadows1"
                  }
                />
              )}
              <div className="navPicDiv">
                <Image className="navPic" alt={name} src={pic} />
              </div>
              {!!collapsedValue && (
                <div className="sideNavName">
                  <p className="navName">{name}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default SideNav;
