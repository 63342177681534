import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import "./ProjectCarousel.css";
import Carousel from "react-multi-carousel";
import { useRecoilValue } from "recoil";
import AddNewProject from "./AddNewProject";
import "react-multi-carousel/lib/styles.css";
import rightslide from "../../../../assets/rightslide.png";
import leftslide from "../../../../assets/leftslide.png";
import EachProject from "./EachProject";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { collapsed } from "../../../../states/collapseNav";

function ProjectCarousel({
  data,
  setName,
  switched,
  filter,
  type,
  idSounds,
  setIdSounds,
}) {
  const { width } = useWindowDimensions();
  const [datas, setDatas] = useState(data);
  const [folderId, setFolderId] = useState("");
  const isCollapsed = useRecoilValue(collapsed);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: type ? 3 : 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  function ButtonGroup({
    next,
    previous,
    // goToSlide,
    //  ...rest
  }) {
    // const {
    //   carouselState: { currentSlide },
    // } = rest;
    return (
      <div
        className="carousel-button-group"
        style={width <= 768 ? { display: "none" } : {}}
      >
        {/* {currentSlide !== 0 && ( */}
        <Image
          src={leftslide}
          alt="left"
          className={!type ? "slide" : "slides"}
          onClick={() => previous()}
        />
        {/* )} */}
        <Image
          className={!type ? "slide1" : "slides1"}
          src={rightslide}
          alt="left"
          onClick={() => next()}
        />

        {/* <Button onClick={() => goToSlide(currentSlide + 1)}>
          Go to any slide
        </Button> */}
      </div>
    );
  }
  useEffect(() => {
    setDatas(data);
  }, [data]);
  if (!type) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        <div className={width > 768 ? "flexD" : ""}>
          {width > 768 && (
            <AddNewProject value="Add sound folder" setName={setName} />
          )}
          {datas &&
          datas.soundFolders &&
          datas.soundFolders.length &&
          !switched ? (
            <div
              className={
                width < 426 && isCollapsed
                  ? "ProjectCarousel1"
                  : "ProjectCarousel"
              }
            >
              <Carousel
                responsive={responsive}
                autoPlay={false}
                autoPlaySpeed={99999999999999999999}
                keyBoardControl
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                arrows={false}
                customButtonGroup={<ButtonGroup />}
                renderButtonGroupOutside
                containerClass="carousel-container"
                infinite
              >
                {datas.soundFolders.map((r) => {
                  return (
                    <div key={r.id}>
                      <EachProject
                        folder={r}
                        idSounds={idSounds}
                        setIdSounds={setIdSounds}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            <div className="mobileSoundFolder">
              {width < 769 && (
                <div className="mobileSoundFolderAddNew">
                  <AddNewProject value="Add sound folder" setName={setName} />
                </div>
              )}
              {datas &&
                Array.isArray(datas.soundFolders) &&
                datas.soundFolders.length &&
                datas.soundFolders.map((r) => {
                  return (
                    <div key={r.id} className="soundF">
                      <EachProject
                        folder={r}
                        dpd="dpd"
                        setIds={(ids) => setFolderId(ids)}
                        fid={folderId}
                        data={datas}
                        filter={filter}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {width > 768 ? (
        <div
          className={
            width < 426 && isCollapsed
              ? "ProjectCarousel1"
              : "ProjectCarousel10"
          }
        >
          <Carousel
            responsive={responsive}
            autoPlay={false}
            autoPlaySpeed={99999999999999999999}
            keyBoardControl
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside
            containerClass="carousel-container"
            infinite
          >
            {[1, 2, 3].map((r, i) => {
              return (
                <div key={r}>
                  <EachProject type i={i} />
                </div>
              );
            })}
          </Carousel>
        </div>
      ) : (
        <div className="mobileSoundFolder">
          {[1, 2, 3].map((r, i) => {
            return (
              <div key={r} className="soundF">
                <EachProject type i={i} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default ProjectCarousel;
