import React, { useEffect, useState, useRef } from "react";
import { useRecoilValue } from "recoil";
import { showNotification } from "@mantine/notifications";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import "./SoundLibrary.css";
import SearchFavoriteComponent from "./components/SearchFavoriteComponent";
import SearchSlider from "../SoundLibraryComponents/SearchSlider";
import ProjectCarousel from "./ProjectComponents/ProjectCarousel";
import AddNoFavorite from "./FavoriteComponents/AddNoFavorite";
import FavoriteContents from "./FavoriteComponents/FavoriteContents";
import { collapsed } from "../../../states/collapseNav";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import { userState } from "../../../states/user";
import {
  getMetadata,
  downloadSignedUrl,
  searchSoundLibrary,
} from "../../../services/soniqServices";
import { bootstrapState } from "../../../states/bootstrap";

function SoundLibrary() {
  const [loading, setLoading] = useState(true);
  const [searchSliderSelected, setSearchSliderSelected] = useState([]);
  const [searched, setSearched] = useState("");
  const [searchedData, setSearchedData] = useState("");
  const firstRender = useRef(true);
  const firstRenders = useRef(true);
  const bootstrap = useRecoilValue(bootstrapState);
  const { width } = useWindowDimensions();
  const isCollapsed = useRecoilValue(collapsed);
  const [data, setData] = useState({ labels: [], soundLibrary: [] });
  const token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${
      process.env.REACT_APP_AUTH_USER_POOLWEB_CLIENT_ID
    }.${useRecoilValue(userState).Username}.idToken`
  );
  const downloadsignedUrl = async (objectKey) => {
    try {
      setLoading(true);
      const obj =
        bootstrap &&
        bootstrap.length &&
        bootstrap.find((r) => r.name === "media-library");
      const path =
        obj && obj.paths.includes("/media-library")
          ? `/media-library/download/${objectKey}`
          : "";
      const endpoint = obj && obj.endpoint;
      const response = await downloadSignedUrl(token, path, endpoint);
      if (
        response.request &&
        response.request.status &&
        response.request.status === 400 &&
        response.request.responseURL
        //  &&
        // response.request.responseUrl.match("/s3.eu-north-1.amazonaws.com/")
      ) {
        setLoading(false);
        return response.request.responseURL;
      }
    } catch (e) {
      showNotification({
        color: "red",
        title: "Error!",
        message: "Error downloading Mp3, Please refresh the page or try again!",
      });
      setLoading(false);
      return null;
    }
  };
  const createDataSet = (url, r) => {
    const soundLibraryObj = {};
    soundLibraryObj["mediaId"] = r.objectKey;
    soundLibraryObj["createdAt"] = r.createdAt;
    soundLibraryObj["mediaLibraryData"] = {};
    soundLibraryObj["mediaLibraryData"]["objectKey"] = r.objectKey;
    soundLibraryObj["mediaLibraryData"]["size"] = r.size;
    soundLibraryObj["mediaLibraryData"]["organization"] = r.organization;
    soundLibraryObj["mediaLibraryData"]["creator"] = r.creator;
    soundLibraryObj["mediaLibraryData"]["collection"] = r.collection;
    soundLibraryObj["mediaLibraryData"]["favorite"] = r.favorite;
    soundLibraryObj["mediaLibraryData"]["id"] = r.id;
    soundLibraryObj["mediaLibraryData"]["name"] = r.name;
    soundLibraryObj["mediaLibraryData"]["labels"] = r.labels;
    soundLibraryObj["mediaLibraryData"]["signedUrl"] = url;
    return soundLibraryObj;
  };
  const getmetadata = async () => {
    try {
      setLoading(true);
      const datas = { ...data };
      datas["labels"] = [];
      datas["soundLibrary"] = [];
      const obj =
        bootstrap &&
        bootstrap.length &&
        bootstrap.find((r) => r.name === "metadata");
      const path = obj && obj.paths.includes("/metadata") ? "/metadata" : "";
      const endpoint = obj && obj.endpoint;
      const response = await getMetadata(token, path, endpoint);
      if (response && response.status === 200) {
        if (
          response.data.metadata &&
          Array.isArray(response.data.metadata) &&
          response.data.metadata.length > 0
        ) {
          response.data.metadata.forEach((r) => {
            downloadsignedUrl(r.objectKey)
              .then((url) => {
                if (url) {
                  const soundLibraryObj = createDataSet(url, r);
                  const newData = { ...datas };
                  newData.labels = [...newData.labels, ...response.data.labels];
                  newData.soundLibrary.push(soundLibraryObj);
                  if (!searched || searched.length === 0) {
                    setData(newData);
                  }
                }
              })
              .catch();
          });

          return;
        }
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Error fetching data, Please refresh the page",
        });
        return;
      }
      setLoading(false);
      return true;
    } catch (e) {
      showNotification({
        color: "red",
        title: "Error!",
        message: "Error fetching data, Please refresh the page or try again!",
      });
      setLoading(false);
      return false;
    }
  };
  const searchsoundlibrary = async (e) => {
    try {
      setLoading(true);
      const datas = { ...data };
      datas["labels"] = [];
      datas["soundLibrary"] = [];
      const obj =
        bootstrap &&
        bootstrap.length &&
        bootstrap.find((r) => r.name === "metadata");
      const path =
        obj && obj.paths.includes("/metadata") ? "/metadata/search" : "";
      const endpoint = obj && obj.endpoint;
      const response = await searchSoundLibrary(token, e, path, endpoint);
      if (response && response.status === 200) {
        if (
          response.data.searchResult &&
          Array.isArray(response.data.searchResult) &&
          response.data.searchResult.length > 0
        ) {
          response.data.searchResult.forEach((r) => {
            downloadsignedUrl(r.objectKey)
              .then((url) => {
                if (url) {
                  const soundLibraryObj = createDataSet(url, r);
                  const newData = { ...datas };
                  newData.labels = [...newData.labels, ...response.data.labels];
                  newData.soundLibrary.push(soundLibraryObj);
                  setSearchedData(newData);
                }
              })
              .catch();
          });
          return;
        }
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Something went wrong",
        });
      }
      setLoading(false);
      return true;
    } catch (er) {
      showNotification({
        color: "red",
        title: "Error!",
        message: "Something went wrong or try again",
      });
      setLoading(false);
      return false;
    }
  };
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else if (bootstrap instanceof Array && bootstrap.length) {
      getmetadata()
        .then(() => {})
        .catch(() => {});
    }
  }, [bootstrap]);
  useEffect(() => {
    if (firstRenders.current) {
      firstRenders.current = false;
    } else if (searched && !searchSliderSelected.length) {
      searchsoundlibrary({ name: searched })
        .then(() => {})
        .catch(() => {});
    } else if (searched && searchSliderSelected.length) {
      searchsoundlibrary({
        name: searched,
        labels: [...searchSliderSelected],
      })
        .then(() => {})
        .catch(() => {});
    }
  }, [searched, searchSliderSelected]);
  if (!loading) {
    return (
      <div
        className={`${
          isCollapsed ? "" : width < 769 ? "favoriteClass" : "ms-3"
        } mt-5 me-3`}
      >
        <div className="searchBar favorite">
          <SearchFavoriteComponent
            toBeSearched="soundLibrary"
            searchText="Vehicle, Wedding, Fireplace, Crowed..."
            letsSetSearched={(e) => setSearched(e)}
          />
        </div>
        {(!searched || !searched.length) && (
          <>
            <div className="soundLibraryCarouselContent">
              <ProjectCarousel type />
            </div>
            <div className="soundLibraryCarouselButton">
              <button type="button" className="showAll">
                Show all
              </button>
            </div>
          </>
        )}
        {searched && searched.length && (
          <div className="mt-3">
            <SearchSlider
              data={searchedData["labels"]}
              searchSliderSelected={searchSliderSelected}
              setSearchSlider={(e) => {
                if (
                  searchSliderSelected.length &&
                  searchSliderSelected.includes(e)
                ) {
                  const newArr = [...searchSliderSelected];
                  newArr.splice(newArr.indexOf(e), 1);
                  setSearchSliderSelected(newArr);
                } else {
                  setSearchSliderSelected((oldArray) => [...oldArray, e]);
                }
              }}
            />
          </div>
        )}
        <div style={{ display: "flex" }} className="mt-5">
          <p className="headerText">Editor&#39;s picks</p>
        </div>
        <p className="headerBottom">Hand picked sounds by our music experts</p>
        {!!(!data["soundLibrary"].length && <AddNoFavorite />)}
        {!!data["soundLibrary"].length && (
          <FavoriteContents
            datas={searched ? searchedData : data}
            getF={() => getmetadata()}
            soundLibrary
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="loader">
        <ClimbingBoxLoader color="#36d7b7" />
      </div>
    );
  }
}

export default SoundLibrary;
