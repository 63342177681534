import React from "react";
import "../general.css";
import "@aws-amplify/ui-react/styles.css";
import { Authenticator } from "@aws-amplify/ui-react";
import InsidePage from "./InsidePage";
import "./loginPage.css";

function LoginPage() {
  return (
    <div className="logPageGen">
      <Authenticator
        signUpAttributes={["email", "name"]}
        className="loginScreen"
      >
        {({ signOut, user }) => {
          return <InsidePage so={signOut} usr={user} />;
        }}
      </Authenticator>
    </div>
  );
}

export default LoginPage;
