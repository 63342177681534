import React from "react";
import Personal from "./settingsPages/Personal";
import Account from "./settingsPages/Account";
import Notifications from "./settingsPages/Notifications";
import Privacy from "./settingsPages/Privacy";

function RenderSettings({ name }) {
  switch (name) {
    case "Personal":
      return <Personal />;
    case "Account":
      return <Account />;
    case "Notifications":
      return <Notifications />;
    case "Privacy":
      return <Privacy />;
    default:
      return <Personal />;
  }
}

export default RenderSettings;
