import React from "react";
import { Table, Image } from "react-bootstrap";
import projectListdefault from "../../../../assets/projectListdefault.png";
import threedots from "../../../../assets/threedots.png";
import "./TopPerformingSounds.css";

function TopPerformingSounds({ topSounds }) {
  return (
    <div className="TopPerformingSounds">
      <div className="topPerformingSoundsTitleDiv">
        <p className="headerText">Top Performing Sounds</p>
        <Image src={threedots} className="options" alt="options" />
      </div>
      <div className="topPerformingSoundsContent">
        <Table responsive>
          <tbody>
            <tr className="totalSoundsHeading">
              <td className="headingTd">Rating</td>
              <td className="headingTd">Sound</td>
              <td className="headingTd">Collection</td>
              <td className="headingTd">Total times played</td>
            </tr>
            {topSounds &&
              topSounds["top_sounds"].length &&
              topSounds["top_sounds"].map((sound, index) => {
                return (
                  <tr
                    className="totalSoundsBody breakLine"
                    key={sound["objectKey"]}
                  >
                    <td
                      className={`bodyTd rating${
                        index === 0 ? " addPadding" : ""
                      }`}
                    >
                      {index + 1}
                    </td>
                    <td
                      className={`bodyTd sounds${
                        index === 0 ? " addPadding" : ""
                      }`}
                    >
                      <span id="topSoundImage">
                        <Image
                          alt="topSoundImage"
                          className="topSoundImage"
                          src={
                            sound["topSoundImage"]
                              ? sound["topSoundImage"]
                              : projectListdefault
                          }
                        />
                      </span>
                      <span id="nameColl">
                        <span id="name">{sound["name"]}</span>
                        <br />
                        <span id="coll">{sound["collection"]}</span>
                      </span>
                    </td>
                    <td
                      className={`bodyTd collection${
                        index === 0 ? " addPadding" : ""
                      }`}
                    >
                      {sound["collection"]}
                    </td>
                    <td
                      className={`bodyTd total-times-played${
                        index === 0 ? " addPadding" : ""
                      }`}
                    >
                      {sound["count"]}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default TopPerformingSounds;
