import axios from "axios";

export const getProjects = async (token, path, endpoint) => {
  try {
    const config = {
      method: "get",
      url: `${endpoint}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    // console.log(err);
    return err;
  }
};
export const getProjectById = async (token, path, endpoint) => {
  try {
    const config = {
      method: "get",
      url: `${endpoint}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    // console.log(err);
    return err;
  }
};
export const postProjects = async (token, data, path, endpoint) => {
  try {
    const config = {
      method: "post",
      url: `${endpoint}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    // console.log(err);
    return err;
  }
};
export const postFolder = async (token, data, path, endpoint) => {
  try {
    const config = {
      method: "post",
      url: `${endpoint}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    };
    const response = await axios(config);
    // console.log(response);
    return response;
  } catch (err) {
    // console.log(err);
    return err;
  }
};
export const getFavorites = async (token, path, endpoint) => {
  try {
    const config = {
      method: "get",
      url: `${endpoint}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    // console.log(err);
    return err;
  }
};
export const getMetadata = async (token, path, endpoint) => {
  try {
    const config = {
      method: "get",
      url: `${endpoint}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    // console.log(err);
    return err;
  }
};
export const downloadSignedUrl = async (token, path, endpoint) => {
  if (token && path && endpoint) {
    try {
      const config = {
        method: "get",
        url: `${endpoint}${path}`,
        maxRedirects: 100,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios(config);
      return response;
    } catch (err) {
      return err;
    }
  } else {
    try {
      const config = {
        method: "get",
        url: path,
      };
      const response = await axios(config);
      return response;
    } catch (err) {
      return err;
    }
  }
};
export const getBootstrap = async () => {
  try {
    const config = {
      method: "GET",
      url: `https://${process.env.REACT_APP_STACK_NAME || "prod"}-bootstrap.${
        process.env.REACT_APP_SUB_DOMAIN || "dev"
      }.soniqsound.se/api/bootstrap`,
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    // console.log(err);
    return err;
  }
};
export const postExistingProjectFavorite = async (
  token,
  data,
  path,
  endpoint
) => {
  try {
    const config = {
      method: "post",
      url: `${endpoint}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    return err;
  }
};
export const removeFavorite = async (token, data, path, endpoint) => {
  try {
    const config = {
      method: "delete",
      url: `${endpoint}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    return err;
  }
};
export const addFavorite = async (token, data, path, endpoint) => {
  try {
    const config = {
      method: "put",
      url: `${endpoint}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    return err;
  }
};
export const searchSoundLibrary = async (token, data, path, endpoint) => {
  try {
    const config = {
      method: "post",
      url: `${endpoint}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    return err;
  }
};
export const searchFavorite = async (token, data, path, endpoint) => {
  try {
    const config = {
      method: "post",
      url: `${endpoint}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    return err;
  }
};
export const getStats = async (token, path, endpoint) => {
  try {
    const config = {
      method: "get",
      url: `${endpoint}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    // console.log(err);
    return err;
  }
};
