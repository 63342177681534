import React, { useEffect, useState, useRef } from "react";
import { NavDropdown, Image } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { showNotification } from "@mantine/notifications";
import "./Favorites.css";
import SearchFavoriteComponent from "./components/SearchFavoriteComponent";
import AddNoFavorite from "./FavoriteComponents/AddNoFavorite";
import FavoriteContents from "./FavoriteComponents/FavoriteContents";
import { collapsed } from "../../../states/collapseNav";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import navdrpicn from "../../../assets/navdrpdwnicn.png";
import { userState } from "../../../states/user";
import { getFavorites, searchFavorite } from "../../../services/soniqServices";
import { bootstrapState } from "../../../states/bootstrap";

function Favorites() {
  // const navigate = useNavigate();
  const [searched, setSearched] = useState("");
  const [loading, setLoading] = useState(true);
  const firstRender = useRef(true);
  const bootstrap = useRecoilValue(bootstrapState);
  const { width } = useWindowDimensions();
  const isCollapsed = useRecoilValue(collapsed);
  const [filtered, setFiltered] = useState("Recently added");
  // const [dropped, setDropped] = useState(false);
  const [data, setData] = useState("");
  const token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${
      process.env.REACT_APP_AUTH_USER_POOLWEB_CLIENT_ID
    }.${useRecoilValue(userState).Username}.idToken`
  );
  const getfavorites = async () => {
    try {
      setLoading(true);
      const obj =
        bootstrap &&
        bootstrap.length &&
        bootstrap.find((r) => r.name === "favorite");
      const path = obj && obj.paths.includes("/favorite") ? "/favorite" : "";
      const endpoint = obj && obj.endpoint;
      const response = await getFavorites(token, path, endpoint);
      if (response && response.status === 200) {
        if (!searched || searched.length === 0) {
          response.data &&
          response.data.sounds &&
          Array.isArray(response.data.sounds) &&
          response.data.sounds.length > 0
            ? setData(response.data.sounds)
            : setData("");
        }
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Error fetching data, Please refresh the page",
        });
      }
      setLoading(false);
      return true;
    } catch (e) {
      showNotification({
        color: "red",
        title: "Error!",
        message: "Error fetching data, Please refresh the page or try again!",
      });
      setLoading(false);
      return false;
    }
  };

  const searchfavorites = async (e) => {
    try {
      setLoading(true);
      const datas = { ...data };
      datas["labels"] = [];
      datas["soundLibrary"] = [];
      const obj =
        bootstrap &&
        bootstrap.length &&
        bootstrap.find((r) => r.name === "favorite");
      const path =
        obj && obj.paths.includes("/favorite") ? "/favorite/search" : "";
      const endpoint = obj && obj.endpoint;
      const response = await searchFavorite(token, e, path, endpoint);
      if (response && response.status === 200) {
        response.data &&
        Array.isArray(response.data) &&
        response.data.length > 0
          ? setData(response.data)
          : setData("");
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Something went wrong",
        });
      }
      setLoading(false);
      return true;
    } catch (er) {
      showNotification({
        color: "red",
        title: "Error!",
        message: "Something went wrong or try again",
      });
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else if (bootstrap instanceof Array && bootstrap.length) {
      if (searched && searched.length !== 0) {
        searchfavorites({ name: searched })
          .then(() => {})
          .catch(() => {});
      } else {
        getfavorites()
          .then(() => {})
          .catch(() => {});
      }
    } else {
      // navigate("/console");
    }
  }, [bootstrap, searched]);
  const filterIcon = (
    <div className="sortDiv">
      <p className="toBeSorted">Sort:</p>
      <p className="toBeSorted">&nbsp;{filtered}</p>
      <Image
        // src={dropped ? rotatednavdrpicn : navdrpicn}
        src={navdrpicn}
        className="sortIcon"
      />
    </div>
  );
  if (!loading) {
    return (
      <div
        className={`${
          isCollapsed ? "" : width < 769 ? "favoriteClass" : "ms-3"
        } mt-5 me-3`}
      >
        <div className="searchBar favorite">
          <SearchFavoriteComponent
            toBeSearched="favorite"
            searchText="Vehicle, Wedding, Fireplace, Crowed..."
            letsSetSearched={(e) => setSearched(e)}
          />
        </div>
        <div style={{ display: "flex" }} className="mt-5">
          <p className="headerTexts">Favorites</p>
          {/* <p className="subHeader">{filtered}</p> */}
          {width <= 425 && !isCollapsed && (
            <NavDropdown
              align="start"
              title={filterIcon}
              id="filterDrpFav"
              className="ms-auto"
            >
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Recently added")}
              >
                Recently added
              </NavDropdown.Item>
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Alphabetical")}
              >
                Alphabetical
              </NavDropdown.Item>
              {/* <NavDropdown.Item
              className="insideNavButton"
              onClick={() => setFiltered("Popular")}
            >
              Popular
            </NavDropdown.Item> */}
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Artist")}
              >
                Artist
              </NavDropdown.Item>
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Collection")}
              >
                Collection
              </NavDropdown.Item>
            </NavDropdown>
          )}
          {width > 425 && (
            <NavDropdown
              align="start"
              title={filterIcon}
              id="filterDrpFav"
              className="ms-auto me-4"
            >
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Recently added")}
              >
                Recently added
              </NavDropdown.Item>
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Alphabetical")}
              >
                Alphabetical
              </NavDropdown.Item>
              {/* <NavDropdown.Item
              className="insideNavButton"
              onClick={() => setFiltered("Popular")}
            >
              Popular
            </NavDropdown.Item> */}
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Artist")}
              >
                Artist
              </NavDropdown.Item>
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Collection")}
              >
                Collection
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </div>
        {!!(!data || (!data.length && <AddNoFavorite />))}
        {!!(data && data.length) && (
          <FavoriteContents
            filter={filtered}
            datas={data}
            getF={() => getfavorites()}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="loader">
        <ClimbingBoxLoader color="#36d7b7" />
      </div>
    );
  }
}

export default Favorites;
