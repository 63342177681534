import React, { useState, useEffect, useRef } from "react";
import { NavDropdown, Image } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { showNotification } from "@mantine/notifications";
import SearchFavoriteComponent from "../components/SearchFavoriteComponent";
import "./ProjectsHome.css";
import { collapsed } from "../../../../states/collapseNav";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import navdrpicn from "../../../../assets/navdrpdwnicn.png";
import ProjectList from "./ProjectList";
import { postProjects } from "../../../../services/soniqServices";
import { userState } from "../../../../states/user";
import { bootstrapState } from "../../../../states/bootstrap";
import { projectState } from "../../../../states/projects";

function ProjectsHome() {
  const projects = useRecoilValue(projectState);
  // const navigate = useNavigate();
  const bootstrap = useRecoilValue(bootstrapState);
  const firstRender = useRef(true);
  const { width } = useWindowDimensions();
  const isCollapsed = useRecoilValue(collapsed);
  const [dropped, setDropped] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState("Name");
  // const [data, setData] = useState("");
  const [searchedData, setSearchedData] = useState("");
  const [searched, setSearched] = useState("");
  const token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${
      process.env.REACT_APP_AUTH_USER_POOLWEB_CLIENT_ID
    }.${useRecoilValue(userState).Username}.idToken`
  );

  const setArrayForData = async (e) => {
    // setData((elements) => ({
    //   ...elements,
    //   ["projects"]: elements["projects"].concat(e),
    // }));
    try {
      setLoading(true);
      const obj =
        bootstrap &&
        bootstrap.length &&
        bootstrap.find((r) => r.name === "project");
      const path = obj && obj.paths.includes("/project") ? "/project" : "";
      const endpoint = obj && obj.endpoint;
      const response = await postProjects(token, e, path, endpoint);
      if (response && response.status === 201) {
        showNotification({
          color: "blue",
          title: "Success!",
          message: "Project added successfully",
        });
        const data = [...searchedData];
        data.push(response["data"]);
        setSearchedData(data);
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Either project already exists or something went wrong",
        });
      }
      setLoading(false);
      return true;
    } catch (er) {
      showNotification({
        color: "red",
        title: "Error!",
        message:
          "Either project already exists or something went wrong or try again",
      });
      setLoading(false);
      return false;
    }
  };
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      if (!searched || !searched.length) {
        setSearchedData(bootstrap.projects);
      }
    } else if (searched && searched.length) {
      const datas = projects;
      const result = datas.projects.filter(({ projectName: obj }) =>
        JSON.stringify(obj).toLowerCase().includes(searched.toLowerCase())
      );
      setSearchedData(result);
    } else {
      setSearchedData(projects.projects);
    }
  }, [searched, projects]);
  useEffect(() => {
    if (bootstrap instanceof Array && bootstrap.length) {
      // getprojects()
      //   .then(() => {})
      //   .catch(() => {});
    } else {
      // navigate("/console");
    }
  }, []);
  const filterIcon = (
    <div className="sortDiv">
      <p className="toBeSorted">Sort:</p>
      <p className="toBeSorted">&nbsp;{filtered}</p>
      <Image src={navdrpicn} className="sortIcon" />
    </div>
  );
  if (loading) {
    return (
      <div
        className={`${
          isCollapsed
            ? ""
            : width < 769
            ? width > 425
              ? "projectClassName"
              : "projectClassNem"
            : "ms-5"
        } mt-5 me-3`}
      >
        <div className="searchBar favorite">
          <SearchFavoriteComponent
            toBeSearched="projects"
            searchText="Search project name"
            letsSetSearched={(e) => setSearched(e)}
          />
        </div>
        <div style={{ display: "flex" }} className="mt-5">
          <p className="headerText">Projects</p>
          {/*  <p className="subHeader">{filtered}</p> */}
          {width <= 425 && !isCollapsed && (
            <NavDropdown
              align="start"
              title={filterIcon}
              id="filterDrpFrSrt"
              className="ms-auto"
              onClick={() => setDropped(!dropped)}
            >
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Name")}
              >
                Name
              </NavDropdown.Item>
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Edited At")}
              >
                Edited At
              </NavDropdown.Item>
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Created At")}
              >
                Created At
              </NavDropdown.Item>
            </NavDropdown>
          )}
          {width > 425 && (
            <NavDropdown
              align="start"
              title={filterIcon}
              id="filterDrpFrSrt"
              className="ms-auto me-4"
              onClick={() => setDropped(!dropped)}
            >
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Name")}
              >
                Name
              </NavDropdown.Item>
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Edited At")}
              >
                Edited At
              </NavDropdown.Item>
              <NavDropdown.Item
                className="insideNavButton"
                onClick={() => setFiltered("Created At")}
              >
                Created At
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </div>
        <ProjectList
          list={searchedData}
          filtered={filtered}
          pushInList={(e) => {
            setArrayForData(e);
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="loader">
        <ClimbingBoxLoader color="#36d7b7" />
      </div>
    );
  }
}

export default ProjectsHome;
