import React, { useRef, useEffect, useState } from "react";
import { showNotification } from "@mantine/notifications";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { useRecoilValue } from "recoil";
import { Row, Col } from "react-bootstrap";
import "./Dashboard.css";
import { userState } from "../../../states/user";
import { bootstrapState } from "../../../states/bootstrap";
import { getStats } from "../../../services/soniqServices";
import CardComponent from "./dashboardPages/CardComponent";
import LineChartComponent from "./dashboardPages/LineChartComponent";
import TopPerformingSounds from "./dashboardPages/TopPerformingSounds";

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const { Username } = useRecoilValue(userState);
  const firstRender = useRef(true);
  const bootstrap = useRecoilValue(bootstrapState);
  const [data, setData] = useState("");
  const token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${
      process.env.REACT_APP_AUTH_USER_POOLWEB_CLIENT_ID
    }.${useRecoilValue(userState).Username}.idToken`
  );
  const getstats = async () => {
    try {
      setLoading(true);
      const obj =
        bootstrap &&
        bootstrap.length &&
        bootstrap.find((r) => r.name === "stats");
      const path = obj && obj.paths.includes("/stats") ? "/stats" : "/stats";
      // const endpoint = obj && obj.endpoint;
      const endpoint = `https://mobiux-stats.dev.soniqsound.se/api`;
      const response = await getStats(token, path, endpoint);
      if (response && response.status === 200) {
        response.data && Object.keys(response.data).length
          ? setData(response.data)
          : setData("");
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Error fetching data, Please refresh the page",
        });
      }
      setLoading(false);
      return true;
    } catch (e) {
      showNotification({
        color: "red",
        title: "Error!",
        message: "Error fetching data, Please refresh the page or try again!",
      });
      setLoading(false);
      return false;
    }
  };
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else if (bootstrap instanceof Array && bootstrap.length) {
      getstats()
        .then(() => {})
        .catch(() => {});
    } else {
      // navigate("/console");
    }
  }, [bootstrap]);
  if (loading) {
    return (
      <div className="loader">
        <ClimbingBoxLoader color="#36d7b7" />
      </div>
    );
  } else {
    return (
      <div className="Dashboard">
        <div className="headerSection">
          <p className="welcomeText">Hello, {Username}</p>
          <p className="welcomeTagText">This is your private dashboard</p>
        </div>
        <div className="cardSection">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <CardComponent
                projects={data["projects"] && data["projects"]}
                totalSoundsPlayed={
                  data["total_sounds_played"] && data["total_sounds_played"]
                }
                soundsUsed={data["sounds_used"] && data["sounds_used"]}
              />
            </Col>
            <Col lg={6} md={6} sm={12} style={{ alignSelf: "center" }}>
              <div className="lineChartTitleDiv">
                <p className="lineChartTitle">
                  Sound statistics (sound played per month)
                </p>
              </div>
              <LineChartComponent soundsHist={data["sounds_hist"]} />
            </Col>
          </Row>
        </div>
        <div className="topPerformingSoundsDiv">
          <TopPerformingSounds topSounds={data["top_sounds"]} />
        </div>
      </div>
    );
  }
}

export default Dashboard;
