import React from "react";
import "./AddNoFavorite.css";
import { Image } from "react-bootstrap";
import favourite from "../../../../assets/favourite.png";

function AddNoFavorite() {
  return (
    <div className="addNoFavorite mb-4 me-4">
      <Image src={favourite} className="favIcon noFavorites" alt="favIcon" />
      <br />
      <p className="text-center ms-4 noFav">No favorites added yet.</p>
    </div>
  );
}

export default AddNoFavorite;
