import React from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import fornotfor from "./assets/404.png";
import fiveHundread from "./assets/500.png";
import "./FourNotFour.css";

function ErrorPages({ type }) {
  const navigate = useNavigate();
  return (
    <div className="pageNotFound">
      <Image
        src={!type ? fornotfor : fiveHundread}
        className="fornotforimg"
        alt="logo"
      />
      <p className="fornotforText text-center">{!type ? 404 : 500}</p>
      <p className="itseemsLike text-center">
        {!type
          ? `It seems like we could not find the page you're seeking.`
          : `It seems like something went wrong on our end.`}
      </p>
      <p className="toGetBack text-center">
        {!type
          ? `To get back to the dashboard, click the link below.`
          : `Try refreshing the page or clicking the link below.`}
      </p>
      <button
        className="gtodb"
        type="button"
        onClick={() => navigate("/console", { replace: true })}
      >
        <p className="txtbtn">Go to Dashboard</p>
      </button>
    </div>
  );
}

export default ErrorPages;
