import React, { useEffect, useState, useRef } from "react";
import "./ProjectDetails.css";
import { useParams, useNavigate } from "react-router-dom";
import { Image, NavDropdown } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { showNotification } from "@mantine/notifications";
import * as moment from "moment";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import returnImg from "../../../../assets/return.png";
import upload from "../../../../assets/upload.png";
import videodemo from "../../../../assets/videodemo.png";
import edit from "../../../../assets/edit.png";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { bootstrapState } from "../../../../states/bootstrap";
import { userState } from "../../../../states/user";
import { getProjectById, postFolder } from "../../../../services/soniqServices";
import projectListdefault from "../../../../assets/projectListdefault.png";
import ProjectCarousel from "./ProjectCarousel";
import rotatednavdrpicn from "../../../../assets/rotatednavdrpdwnicn.png";
import navdrpicn from "../../../../assets/navdrpdwnicn.png";
import { collapsed } from "../../../../states/collapseNav";
import FavoriteContents from "../FavoriteComponents/FavoriteContents";

function ProjectDetails() {
  const firstRender = useRef(true);
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState("Recently added");
  const [dropped, setDropped] = useState(false);
  const [data, setData] = useState("");
  const bootstrap = useRecoilValue(bootstrapState);
  const { id } = useParams();
  const isCollapsed = useRecoilValue(collapsed);
  const navigate = useNavigate();
  const [idSounds, setIdSounds] = useState("");
  const { width } = useWindowDimensions();
  const [switched, setSwitched] = useState(false);
  const token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${
      process.env.REACT_APP_AUTH_USER_POOLWEB_CLIENT_ID
    }.${useRecoilValue(userState).Username}.idToken`
  );
  const getProjectDetails = async () => {
    try {
      setLoading(true);
      const obj =
        bootstrap &&
        bootstrap.length &&
        bootstrap.find((r) => r.name === "project");
      const path =
        obj && obj.paths.includes("/project") ? `/project/${id}` : "";
      const endpoint = obj && obj.endpoint;
      const response = await getProjectById(token, path, endpoint);
      if (response && response.status === 200) {
        response.data.sounds &&
        response.data.soundFolders &&
        Array.isArray(response.data.sounds) &&
        Array.isArray(response.data.soundFolders) &&
        response.data.sounds.length > 0
          ? // &&
            // response.data.soundFolders.length > 0
            setData(response.data)
          : setData("");
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Something went wrong,please refresh the page",
        });
      }
      setLoading(false);
      return true;
    } catch (e) {
      showNotification({
        color: "red",
        title: "Error!",
        message: "Something went wrong,please refresh the page or try again",
      });
      setLoading(false);
      return false;
    }
  };
  const postfolder = async (info) => {
    try {
      setLoading(true);
      const obj =
        bootstrap &&
        bootstrap.length &&
        bootstrap.find((r) => r.name === "project");
      const path =
        obj && obj.paths.includes("/project") ? `/project/${id}/folder` : "";
      const endpoint = obj && obj.endpoint;
      const response = await postFolder(token, { name: info }, path, endpoint);
      if (response && response.status === 201) {
        showNotification({
          color: "blue",
          title: "Success!",
          message: "New soundfolder created",
        });
        getProjectDetails();
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Something went wrong,please refresh the page",
        });
      }
      setLoading(false);
      return true;
    } catch (e) {
      showNotification({
        color: "red",
        title: "Error!",
        message: "Something went wrong,please refresh the page or try again",
      });
      setLoading(false);
      return false;
    }
  };
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else if (id && bootstrap instanceof Array && bootstrap.length) {
      getProjectDetails()
        .then(() => {})
        .catch(() => {});
    } else {
      // navigate(-1);
    }
    // return () => {
    //   setData("");
    // };
  }, [bootstrap]);
  const filterIcon = (
    <div className="sortDiv">
      <p className="toBeSorted">Sort:</p>
      <p className="toBeSorted">&nbsp;{filtered}</p>
      <Image
        src={dropped ? rotatednavdrpicn : navdrpicn}
        className="sortIcon"
      />
    </div>
  );
  const pressSpace = (e, value) => {
    if (e.key === " " || e.code === "Space" || e.keyCode === 32) {
      if (value === "first") {
        navigate(-1);
      } else if (value === "second") {
        if (!switched) {
          setSwitched(true);
        }
      } else if (value === "third") {
        if (switched) {
          setSwitched(false);
        }
      }
    }
    return true;
  };
  const filterSounds = () => {
    return Array.isArray(idSounds) && idSounds.length
      ? data["sounds"].filter(({ soundFolderId }) =>
          idSounds.includes(soundFolderId)
        )
      : data;
  };
  if (!loading) {
    return (
      <div className="projectDetailsClass">
        <div className="project-details-header">
          <div
            className="left-side"
            onClick={() => navigate(-1)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => pressSpace(e, "first")}
          >
            <div className="returnImgDiv">
              <Image src={returnImg} alt="return back" className="returnImg" />
            </div>
            <div>
              <p className="return-text-p">Return to Projects</p>
            </div>
          </div>
          {!!(width > 768) && (
            <div className="right-side text-white">
              <div className="rightDivContents">
                <div className="rightImageDiv">
                  <Image src={upload} alt="upload" className="rightImage" />
                </div>
                <div className="rightTextDiv">
                  <p className="rightDivContentText">upload sound</p>
                </div>
              </div>
              <div className="rightDivContents">
                <div className="rightImageDiv">
                  <Image
                    src={videodemo}
                    alt="videodemo"
                    className="rightImage"
                  />
                </div>
                <div className="rightTextDiv">
                  <p className="rightDivContentText">Video demo</p>
                </div>
              </div>
              <div className="rightDivContents">
                <div className="rightImageDiv">
                  <Image src={edit} alt="edit" className="rightImage" />
                </div>
                <div className="rightTextDiv">
                  <p className="rightDivContentText">Edit</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="project-details-body">
          <div
            className={width <= 768 ? "project-heading1" : "project-heading"}
          >
            <div
              className={
                width <= 768 ? "project-image-div1" : "project-image-div"
              }
            >
              <Image
                src={data && data.image ? data.image : projectListdefault}
                alt="project"
                className="project-image"
              />
            </div>
            <div
              className={
                width <= 768 ? "project-text-div1" : "project-text-div"
              }
            >
              <p className={width <= 768 ? "project-text1" : "project-text"}>
                {data.projectName}
              </p>
              <p
                className={
                  width <= 768 ? "project-small-text1" : "project-small-text"
                }
              >
                {data &&
                  `Edited - ${moment(data.editedAt * 1000).format(
                    "MMMM"
                  )} ${moment(data.editedAt * 1000).format("DD")}, ${moment(
                    data.editedAt * 1000
                  ).format("YYYY")}`}
              </p>
            </div>
          </div>
          {width > 768 && (
            <div className="carousel-start">
              <div className="carousel-heading">
                <p className="carousel-header-text">Sound folder</p>
              </div>
              <div className="carousel-body">
                <ProjectCarousel
                  idSounds={idSounds}
                  setIdSounds={(e) => setIdSounds(e)}
                  data={data}
                  setName={(e) => postfolder(e)}
                />
              </div>
            </div>
          )}
          {width <= 768 && (
            <div className="soundSwitch">
              <div
                className={!switched ? "generalSwitch switches" : "switches"}
                onClick={() => {
                  if (!switched) {
                    setSwitched(true);
                  }
                }}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => pressSpace(e, "second")}
              >
                <p className={!switched ? "switchText tr" : "switchText fl"}>
                  All sounds
                </p>
              </div>
              <div
                className={switched ? "generalSwitch switches" : "switches"}
                onClick={() => {
                  if (switched) {
                    setSwitched(false);
                  }
                }}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => pressSpace(e, "third")}
              >
                <p className={switched ? "switchText1 tr" : "switchText1 fl"}>
                  Sound folder
                </p>
              </div>
            </div>
          )}
          <div className="music-list-div">
            <div
              className={
                width > 768
                  ? "music-list-headings"
                  : "music-list-headingsForPhone"
              }
            >
              {width > 768 && (
                <div className="music-list-heading">
                  <p className="carousel-header-text">All sounds</p>
                </div>
              )}
              <div className="music-list-heading me-1">
                {width <= 425 && !isCollapsed && (
                  <NavDropdown
                    align="start"
                    title={filterIcon}
                    id="filterDrpFrSrt"
                    className="ms-auto"
                    onClick={() => setDropped(!dropped)}
                  >
                    <NavDropdown.Item
                      className="insideNavButton"
                      onClick={() => setFiltered("Recently added")}
                    >
                      Recently added
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="insideNavButton"
                      onClick={() => setFiltered("Oldest")}
                    >
                      Oldest
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="insideNavButton"
                      onClick={() => setFiltered("Title")}
                    >
                      Title
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="insideNavButton"
                      onClick={() => setFiltered("Publisher")}
                    >
                      Publisher
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="insideNavButton"
                      onClick={() => setFiltered("Collection")}
                    >
                      Collection
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </div>
            </div>
            {(width > 768 || !switched) && (
              <div className="music-list-Players mt-3">
                <FavoriteContents
                  filter={filtered}
                  // datas={data.sounds}
                  datas={
                    Array.isArray(idSounds) && idSounds.length
                      ? filterSounds()
                      : data["sounds"]
                  }
                  type="project"
                />
              </div>
            )}
            {switched && width <= 768 && (
              <ProjectCarousel
                data={data}
                setName={(e) => postfolder(e)}
                switched={switched}
                filter={filtered}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="loader">
        <ClimbingBoxLoader color="#36d7b7" />
      </div>
    );
  }
}

export default ProjectDetails;
