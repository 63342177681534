import React, { useState, useEffect } from "react";
import "./EachProject.css";
import { Image, NavDropdown } from "react-bootstrap";
// import { useSetRecoilState, useRecoilValue } from "recoil";
import * as moment from "moment";
import { useNavigate } from "react-router-dom";
import projectListdefault from "../../../../assets/projectListdefault.png";
import projectOptions from "../../../../assets/projectOptions.png";
import rightArrow from "../../../../assets/rightArrow.png";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
// import { folderId } from "../../../../states/folderId";
import FavoriteContents from "../FavoriteComponents/FavoriteContents";

function EachProject({
  project,
  folder,
  dpd,
  setIds,
  fid,
  data,
  filter,
  type,
  i,
  idSounds,
  setIdSounds,
}) {
  // const folderIds = useRecoilValue(folderId);
  const [show, setShow] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [folders, setFolders] = useState(folder);
  const navigate = useNavigate();
  // const setFolderId = useSetRecoilState(folderId);
  const { width } = useWindowDimensions();
  const drpOptionIcon = (
    <Image src={projectOptions} className="drpOptionIcon" />
  );
  const editedAt = !folders && !type ? project.editedAt * 1000 : null;
  // const createdAt = !folders ? project.createdAt * 1000 : null;
  useEffect(() => {
    setFolders(folder);
  }, [folder]);

  const getData = () => {
    const dta = data && data.sounds;
    const res = dta && dta.filter((e) => e.soundFolderId === fid);
    return res;
  };
  const showDropdown = () => {
    setShow(!show);
  };
  const hideDropdown = () => {
    setShow(false);
  };
  const pressSpace = (e) => {
    if (e.key === " " || e.code === "Space" || e.keyCode === 32) {
      !folders
        ? () => navigate(`projectDetails/${project.id}`)
        : () => {
            if (!dpd) {
              // const flders = [...folderIds];
              const flders =
                Array.isArray(idSounds) && idSounds.length ? [...idSounds] : [];
              if (!clicked) {
                setClicked(true);
                flders.push(folder.id);
                setIdSounds(flders);
                // flders.push(folder.id);
                // setFolderId(flders);
              } else {
                setClicked(false);
                // const index = flders.indexOf(folder.id);
                // flders.splice(index, 1);
                // setFolderId(flders);
                const index = flders.indexOf(folder.id);
                flders.splice(index, 1);
                setIdSounds(flders);
              }
            } else if (fid === folder.id) {
              setIds("");
            } else {
              setIds(folder.id);
            }
          };
    }
    return true;
  };
  if (!type) {
    return (
      <>
        <div
          className={width <= 576 && folders ? "EachProject1" : "EachProject"}
          onClick={
            !folders
              ? () => navigate(`projectDetails/${project.id}`)
              : () => {
                  if (!dpd) {
                    const flders =
                      Array.isArray(idSounds) && idSounds.length
                        ? [...idSounds]
                        : [];
                    if (!clicked) {
                      setClicked(true);
                      flders.push(folder.id);
                      setIdSounds(flders);
                      // setFolderId(flders);
                    } else {
                      setClicked(false);
                      const index = flders.indexOf(folder.id);
                      flders.splice(index, 1);
                      setIdSounds(flders);
                      // setFolderId(flders);
                    }
                  } else if (fid === folders.id) {
                    setIds("");
                  } else {
                    setIds(folders && folders.id);
                  }
                }
          }
          onKeyPress={(e) => pressSpace(e)}
          tabIndex={0}
          role="button"
        >
          <div
            className={
              folders
                ? !clicked
                  ? "EachProjectContents1"
                  : width > 769
                  ? "marginFolders"
                  : ""
                : !clicked
                ? width < 769
                  ? ""
                  : "EachProjectContents11"
                : ""
            }
          >
            <div
              className={width <= 576 && folders ? "cardHeader1" : "cardHeader"}
            >
              <div className="projectCardDiv">
                <Image
                  src={
                    !folders
                      ? project.image
                        ? project.image
                        : projectListdefault
                      : folders.image
                      ? folders.image
                      : projectListdefault
                  }
                  alt="Project Name"
                  className="projectImages"
                />
              </div>
              <div className="projectCardDiv">
                <p className="projectNames">
                  {!folders
                    ? project.projectName.length > 15
                      ? `${project.projectName.substring(0, 15)}...`
                      : project.projectName
                    : folders.name.length > 15
                    ? `${folders.name.substring(0, 15)}...`
                    : folders.name}
                </p>
              </div>
              <div className="projectCardDiv">
                <NavDropdown
                  align="start"
                  title={drpOptionIcon}
                  id="dropOption"
                  show={show}
                  onMouseEnter={showDropdown}
                  onMouseLeave={hideDropdown}
                >
                  <NavDropdown.Item
                    className="insideNavButton"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Recently added
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="insideNavButton"
                    onClick={(e) => e.stopPropagation()}
                    //   onClick={() => setFiltered("Alphabetical")}
                  >
                    Alphabetical
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="insideNavButton"
                    onClick={(e) => e.stopPropagation()}
                    //   onClick={() => setFiltered("Popular")}
                  >
                    Popular
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="insideNavButton"
                    onClick={(e) => e.stopPropagation()}
                    //   onClick={() => setFiltered("Artist")}
                  >
                    Artist
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="insideNavButton"
                    onClick={(e) => e.stopPropagation()}
                    //   onClick={() => setFiltered("Collection")}
                  >
                    Collection
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
            <div className={width <= 576 && folders ? "cardBody1" : "cardBody"}>
              <div className={!folders ? "soundChip" : "soundChip1"}>
                <div className="soundNumbersDiv">
                  <p
                    className={
                      !folders
                        ? "soundNumbers"
                        : width <= 576
                        ? "soundNumbers11"
                        : "soundNumbers1"
                    }
                  >
                    {!folders ? project.totalSounds : folders.totalSounds}{" "}
                    Sounds
                  </p>
                </div>
              </div>
              {/* {width <= 576 && (
          <div className="editedDetails">
            <div>
              <p className="editedOn">Edited - {editedAt}</p>
            </div>
          </div>
        )} */}
              {/* {width > 576 && ( */}
              <div className="editedDetails">
                <p className="editedOn">
                  Edited -
                  {!folders
                    ? ` ${moment(editedAt).format("MMMM")} ${moment(
                        editedAt
                      ).format("DD")}, ${moment(editedAt).format("YYYY")}`
                    : ` ${moment(folders.editedAt * 1000).format(
                        "MMMM"
                      )} ${moment(folders.editedAt * 1000).format(
                        "DD"
                      )}, ${moment(folders.editedAt * 1000).format("YYYY")}`}
                </p>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
        {dpd && fid === folder.id && (
          <FavoriteContents type="project" datas={getData()} filter={filter} />
        )}
      </>
    );
  }
  return (
    <div className={width <= 576 ? "EachP1" : i === 2 ? "fdot" : "EachP"}>
      <div className={width <= 576 ? "brdius1" : "brdius"} />
      <div className={width <= 576 ? "cH1" : "cH"}>
        <p className="soundGalText">Wind</p>
        <Image src={rightArrow} alt="rightArrow" className="rightArrow" />
      </div>
    </div>
  );
}

export default EachProject;
