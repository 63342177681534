import React, { useState } from "react";
import "./AddNewProject.css";
import { Image, Modal } from "react-bootstrap";
import addNewPrjBtn from "../../../../assets/addNewPrjBtn.png";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";

function AddNewProject({
  pushInList,
  value,
  setName,
  mediaId,
  setCreateProject,
}) {
  const [show, setShow] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectSlug, setProjectSlug] = useState("");
  const { width } = useWindowDimensions();
  const submitProject = () => {
    const obj = {
      projectName,
      projectSlug,
    };
    if (!mediaId) {
      if (!value) {
        pushInList(obj);
        setProjectName("");
        setProjectSlug("");
        setShow(false);
      } else {
        setName(projectName);
        setProjectName("");
        setProjectSlug("");
        setShow(false);
      }
    } else {
      obj.sounds = [{ mediaId }];
      setCreateProject(obj);
      setProjectName("");
      setProjectSlug("");
      setShow(false);
    }
  };
  const pressSpace = (e) => {
    if (e.key === " " || e.code === "Space" || e.keyCode === 32) {
      setShow(!show);
    }
    return true;
  };
  return (
    <>
      <div
        className={
          !mediaId
            ? width <= 768 && value
              ? "AddNewProject1"
              : value
              ? "AddNewProject"
              : "AddNewProjects"
            : "AddNewProjectmediaId"
        }
        onClick={() => {
          setShow(true);
        }}
        onKeyPress={(e) => {
          pressSpace(e);
        }}
        tabIndex={0}
        role="button"
      >
        <div>
          <p className="addPrjText text-center">
            {!mediaId ? value || "Add Project" : "Create project"}
          </p>
          {!mediaId && (
            <div className="addBtn addPrjContents">
              <Image
                src={addNewPrjBtn}
                className="addNewPrjBtn"
                alt="Add new Project"
              />
            </div>
          )}
        </div>
      </div>
      {/* <button type="button" onClick={() => setShow(!show)}>
        click me
      </button> */}
      <Modal
        id="addProjectModal"
        show={show}
        fullscreen
        onHide={() => setShow(false)}
      >
        <Modal.Header id="addProject" closeButton>
          <Modal.Title />
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
            <p className="heading">{!value ? `Project` : `Folder`} name</p>
            <input
              type="text"
              className="inputFieldProject"
              placeholder="Type here"
              value={projectName}
              onChange={(e) => {
                setProjectName(e.target.value);
                setProjectSlug(
                  e.target.value.replace(/\s/g, "-").toLowerCase()
                );
              }}
            />
            <br />
            {!value && (
              <>
                <p className="heading pSlug">Project slug</p>
                <input
                  type="text"
                  className="inputFieldProject"
                  disabled
                  value={projectSlug}
                />
              </>
            )}
            <br />
            <button
              type="button"
              className="addProjectBtn"
              onClick={() => {
                submitProject();
              }}
            >
              <p className="textAddProject">{value || "add project"}</p>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddNewProject;
