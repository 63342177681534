import React, { useEffect } from "react";
import "./Personal.css";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { userState } from "../../../../states/user";

function Personal() {
  const navigate = useNavigate();
  // const [profileImage, setProfileImage] = useState("");
  const user = useRecoilValue(userState);
  const userName =
    user &&
    user.UserAttributes &&
    user.UserAttributes.length &&
    user.UserAttributes.find((e) => e.Name === "name");
  useEffect(() => {
    if (Object.keys(user).length === 0) {
      navigate("/console");
    }
  }, []);
  return (
    <div>
      <p className="photoText">Photo</p>
      {/* {profileImage && (
        <Image src={profileImage} className="photo" alt="User Photo" />
      )} */}
      {/* {!profileImage && ( */}
      <div className="profileAndButton">
        <div className="dps">
          {userName && userName.Value.length && userName.Value[0]}
        </div>
        <button type="button" className="changedp">
          Change
        </button>
      </div>
      {/* )} */}
      <div className="detailsDiv">
        <div className="fullName">
          <p className="fullNameText">Full name</p>
          <div className="fullNameValue">
            <div className="valueBox">
              <p className="valueText">{userName && userName.Value}</p>
            </div>
          </div>
        </div>
        <div className="userName">
          <p className="fullNameText">Username</p>
          <div className="fullNameValue">
            <div className="valueBox">
              <p className="valueText">{user && user.Username}</p>
            </div>
          </div>
        </div>
        <div className="role">
          <p className="fullNameText">Role</p>
          <div className="fullNameValue">
            <div className="valueBox">
              <p className="valueText">Admin</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Personal;
