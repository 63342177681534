import React from "react";
import "./GetInTouch.css";

function GetInTouch() {
  return (
    <div className="GetInTouch">
      <p className="getintouchHeading">Get in touch</p>
      <div className="getform">
        <div className="getNameFirst">
          <p className="getNameLabel">Name</p>
          <input
            type="text"
            className="nameInput getFormInput"
            placeholder="Name"
          />
        </div>
        <div className="getNameSecond">
          <p className="getNameLabel">Email</p>
          <input
            type="email"
            className="emailInput getFormInput"
            placeholder="Email"
          />
        </div>
        <div className="getNameSecond">
          <p className="getNameLabel">Phone (optional)</p>
          <input
            type="text"
            className="numberInput getFormInput"
            placeholder="Phone"
          />
        </div>
        <div className="getNameSecond">
          <p className="getNameLabel">Message</p>
          <textarea
            rows="6"
            placeholder="Type message here..."
            className="messageInput getFormInput"
          />
        </div>
        <div className="btnDiv">
          <button className="submitBtn" type="button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default GetInTouch;
