import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import "./WorkSliderComponent.css";
import rightNav from "../assets/rightNav.png";
import leftNav from "../assets/leftNav.png";
import pererik from "../assets/pererik.png";
import sanjoy from "../assets/sanjoy.png";
import thomas from "../assets/thomas.png";
import useWindowDimensions from "../customHooks/useWindowDimensions";

function WorkSliderComponent() {
  const { width } = useWindowDimensions();
  const [sliderImages] = useState([
    {
      image: thomas,
      nameWork: "Thomas Aaltonen",
      whoWork: "Co-Founder",
      designationWork: "CEO & Sales Director",
    },
    {
      image: pererik,
      nameWork: "Per-Erik",
      whoWork: "Co-Founder",
      designationWork: "Retail Sound & Product Director",
    },
    {
      image: sanjoy,
      nameWork: "Sanjoy Datta",
      whoWork: "Co-Founder",
      designationWork: "Marketing & Strategy Director",
    },
    {
      image: sanjoy,
      nameWork: "Sanjoy Datta",
      whoWork: "Co-Founder",
      designationWork: "Marketing & Strategy Director",
    },
    {
      image: pererik,
      nameWork: "Per-Erik",
      whoWork: "Co-Founder",
      designationWork: "Retail Sound & Product Director",
    },
    {
      image: thomas,
      nameWork: "Thomas Aaltonen",
      whoWork: "Co-Founder",
      designationWork: "CEO & Sales Director",
    },
  ]);
  const [sliderIndexFrom, setsliderIndexFrom] = useState(0);
  const [sliderIndexTo, setSliderIndexTo] = useState(
    window.innerWidth <= 1024 ? 1 : 2
  );
  const next = () => {
    let nm = 3;
    if (width <= 1024 && width > 768) {
      nm = 2;
    } else if (width <= 768) {
      nm = 1;
    }
    if (sliderIndexTo + nm < sliderImages.length) {
      setSliderIndexTo(sliderIndexTo + nm);
      setsliderIndexFrom(sliderIndexFrom + nm);
    }
  };
  const prev = () => {
    let nm = 3;
    if (width <= 1024 && width > 768) {
      nm = 2;
    } else if (width <= 768) {
      nm = 1;
    }
    if (!(sliderIndexFrom - nm < 0)) {
      setsliderIndexFrom(sliderIndexFrom - nm);
      setSliderIndexTo(sliderIndexTo - nm);
    }
  };
  function handleResize() {
    if (window.innerWidth > 1024) {
      setSliderIndexTo(sliderIndexFrom + 2);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
      setSliderIndexTo(sliderIndexFrom + 1);
    } else if (window.innerWidth <= 768) {
      setSliderIndexTo(sliderIndexFrom);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });
  useEffect(() => {
    handleResize();
  });
  return (
    <div className="WorkSliderComponent">
      <div className="leftNavDiv">
        <Image
          src={leftNav}
          alt="navigation"
          className="leftNav"
          onClick={() => prev()}
        />
      </div>
      <div className="workSlider">
        {sliderImages.map((r, i) => {
          if (i >= sliderIndexFrom && i <= sliderIndexTo) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className="sliderContent" key={i}>
                <Image src={r.image} alt="navigation" className="workPics" />
                <p className="nameWork">{r.nameWork}</p>
                <p className="whoWork">{r.whoWork}</p>
                <p className="designationWork">{r.designationWork}</p>
              </div>
            );
          }
        })}
      </div>
      <div className="rightNavDiv">
        <Image
          src={rightNav}
          alt="navigation"
          className="rightNav"
          onClick={() => next()}
        />
      </div>
    </div>
  );
}

export default WorkSliderComponent;
