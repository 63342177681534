import React, { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Outlet, useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import "./generalConsole.css";
import { isSigned, userState } from "../states/user";
import SideNav from "../components/SideNav";
import { collapsed } from "../states/collapseNav";
import useWindowDimensions from "../customHooks/useWindowDimensions";
import { getProjects, getBootstrap } from "../services/soniqServices";
import { bootstrapState } from "../states/bootstrap";
import { projectState } from "../states/projects";

function InsidePage({ so, usr }) {
  const setBootstrapState = useSetRecoilState(bootstrapState);
  // const bootstrap = useRecoilValue(bootstrapState);
  const { width } = useWindowDimensions();
  const expanded = useRecoilValue(collapsed);
  const setProject = useSetRecoilState(projectState);
  const keys = Object.keys(usr.storage);
  const index = keys.find(
    (e) =>
      e.charAt(e.length - 4) === "D" &&
      e.charAt(e.length - 3) === "a" &&
      e.charAt(e.length - 2) === "t" &&
      e.charAt(e.length - 1) === "a"
  );
  const loggedValue = useRecoilValue(isSigned);
  const setUserState = useSetRecoilState(userState);
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedValue === null || loggedValue === true) {
      setUserState(JSON.parse(usr.storage[index]));
    } else if (!loggedValue) {
      setUserState({});
      so();
      navigate("/");
    }
  }, [loggedValue]);
  const getprojects = async (ep) => {
    try {
      const token = localStorage.getItem(
        `CognitoIdentityServiceProvider.${
          process.env.REACT_APP_AUTH_USER_POOLWEB_CLIENT_ID
        }.${usr && usr.username && usr.username}.idToken`
      );
      const obj = ep && ep.length && ep.find((r) => r.name === "project");
      const path = obj && obj.paths.includes("/project") ? "/project" : "";
      const endpoint = obj && obj.endpoint;
      const response = await getProjects(token, path, endpoint);
      if (response && response.status === 200) {
        setProject(response.data);
      } else {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Something went wrong,please refresh the page",
        });
      }
      return true;
    } catch (e) {
      showNotification({
        color: "red",
        title: "Error!",
        message: "Something went wrong,please refresh the page or try again",
      });
      return false;
    }
  };
  useEffect(() => {
    getBootstrap()
      .then((res) => {
        if (res.status === 200) {
          setBootstrapState(res.data.API.endpoints);
          getprojects(res.data.API.endpoints).then().catch();
        } else {
          showNotification({
            color: "red",
            title: "Error!",
            message: "Error fetching bootstrap data, Please refresh the page",
          });
        }
      })
      .catch(() => {
        showNotification({
          color: "red",
          title: "Error!",
          message: "Some error occured, Please refresh the page or try again",
        });
      });
    // return () => {
    //   setProject({});
    //   setBootstrapState({});
    // };
  }, []);
  return (
    <div className="content">
      <div className={!expanded ? "expanded-sidenav" : "sidenav"}>
        <SideNav />
      </div>
      <div
        className={
          width > 425
            ? !expanded
              ? "expanded-content"
              : "contents"
            : !expanded
            ? "expanded-content1"
            : "contents"
        }
      >
        {/* <Outlet /> */}
        <div className="items">
          {/* <RenderSideMenu name={page} /> */}
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export default InsidePage;
