import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import Amplify from "aws-amplify";
import { NotificationsProvider } from "@mantine/notifications";
import { MantineProvider } from "@mantine/core";
import { useSetRecoilState } from "recoil";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import NavBar from "./components/Navbar";
import { scrollState } from "./states/scroll";
import "./App.css";
import ErrorPages from "./ErrorPages";
import ProjectDetails from "./pages/authenticatedPages/SideMenuContents/ProjectComponents/ProjectDetails";
import Dashboard from "./pages/authenticatedPages/SideMenuContents/Dashboard";
import Favorites from "./pages/authenticatedPages/SideMenuContents/Favorites";
import Help from "./pages/authenticatedPages/SideMenuContents/Help";
import Projects from "./pages/authenticatedPages/SideMenuContents/Projects";
import Settings from "./pages/authenticatedPages/SideMenuContents/Settings";
import SoundLibrary from "./pages/authenticatedPages/SideMenuContents/SoundLibrary";
import ProjectsHome from "./pages/authenticatedPages/SideMenuContents/ProjectComponents/ProjectsHome";

function App() {
  const setScrollState = useSetRecoilState(scrollState);
  const location = useLocation();
  const changeNavbarColor = (e) => {
    if (e.target.scrollTop >= 80) {
      setScrollState(false);
    } else {
      setScrollState(true);
    }
  };

  Amplify.configure({
    Auth: {
      region: `${process.env.REACT_APP_AUTH_REGION || "eu-north-1"}`,
      userPoolId: `${process.env.REACT_APP_AUTH_USER_POOL_ID}`,
      userPoolWebClientId: `${process.env.REACT_APP_AUTH_USER_POOLWEB_CLIENT_ID}`,
    },
  });
  return (
    <MantineProvider>
      <NotificationsProvider>
        <div
          className={location.pathname === "/" ? "appCss" : "appCss1"}
          onScroll={(e) => changeNavbarColor(e)}
        >
          <div className="appD">
            <NavBar />
            <Routes>
              <Route index element={<HomePage />} />
              <Route path="home" element={<HomePage />} />
              <Route path="console" element={<LoginPage />}>
                <Route index element={<Dashboard />} />
                <Route path="sound" element={<SoundLibrary />} />
                <Route path="projects" element={<Projects />}>
                  <Route index element={<ProjectsHome />} />
                  <Route path="projectsHome" element={<ProjectsHome />} />
                  <Route
                    path="projectDetails/:id"
                    element={<ProjectDetails />}
                  />
                  <Route
                    path="*"
                    element={
                      <ErrorPages
                      // type="five"
                      />
                    }
                  />
                </Route>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="settings" element={<Settings />} />
                <Route path="favorites" element={<Favorites />} />
                <Route path="help" element={<Help />} />
                <Route
                  path="*"
                  element={
                    <ErrorPages
                    // type="five"
                    />
                  }
                />
              </Route>
              <Route
                path="*"
                element={
                  <ErrorPages
                  // type="five"
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </NotificationsProvider>
    </MantineProvider>
  );
}

export default App;
